import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../config.js";
import Cookies from "js-cookie";

const KakaoRedirect = () => {
  // REACT_APP_SERVER_DOMAIN_DEV
  // REACT_APP_SERVER_DOMAIN_DEPLOY

  const navigate = useNavigate();
  // const SERVER_IP = "192.168.0.4:8080"; // 배포 전
  // const SERVER_URI = process.env.REACT_APP_SERVER_DOMAIN_DEPLOY; // 배포 후
  //const SERVER_URI = process.env.REACT_APP_SERVER_DOMAIN_DEV;
  const SERVER_URI = config.serverUri;
  const REDIRECT_SERVER_URI = `${SERVER_URI}/login/kakao`;

  useEffect(() => {
    const loginInfo = Cookies.get("lawlow"); // 로그인 정보가 있다면 메인 페이지로 리디렉트
    if (loginInfo) {
      navigate("/main");
    } else {
      if (window.location.pathname === "/oauth/kakao") {
        handleOAuthRedirect();
      }
    }
  }, []);

  // 서버측에 인가 코드 전달
  function handleOAuthRedirect() {
    const code = new URL(document.location.toString()).searchParams.get("code");

    axios
      .get(`${REDIRECT_SERVER_URI}?code=${code}`, { withCredentials: true })
      .then((response) => {
        navigate("/main"); // 로그인 성공시 메인 페이지로 이동
      });
  }

  return <div> 로그인 중입니다. </div>;
};

export default KakaoRedirect;
