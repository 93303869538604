import React, { useState } from "react";
import styled from "styled-components";
import useStore from "../../store.js";
import ModalBasic from "./ModalBasic.jsx";
import Text from "../Text.jsx";
import close_button from "../../img/close_button.png";
import HeightInterval from "../HeightInterval.jsx";
import ModalSubmitCheck from "./ModalSubmitCheck.jsx";
import ModalSubmitCompleted from "./ModalSubmitCompleted.jsx";
import { useMediaQuery } from "react-responsive";

// ModalInquiry 컴포넌트 내에서 Desktop, Mobile 정의
// Desktop, Tablet / 1920-600
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 600, maxWidth: 10000 });
  return isDesktop ? children : null;
};

// Mobile / 599 - 320
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 599 });
  return isMobile ? children : null;
};

// ModalBasic 컴포넌트를 세분화한 문의하기 컴포넌트
/* ModalInquiry에서만 prop 이름을 onCloseInquiry 로 변경한 이유
→ onClose로 해놓은 후, onClose();를 실행하니 ModalInquiry뿐 아니라, 열려있는 모든 Modal창을 닫아버렸다
→ 그래서 onCloseInquiry로 바꾸고 onCloseInquiry(); 를 실행하면, 의도한대로 문의하기 창만 닫힌다
*/
const ModalInquiry = ({ isOpen, onCloseInquiry }) => {
  const { fetchPostRequest } = useStore();

  const [userEmail, setUserEmail] = useState("");
  const [textSubject, setTextSubject] = useState("");
  const [textContents, setTextContents] = useState("");

  const postInquiryToServer = async (e) => {
    // e.preventDefault();
    await fetchPostRequest("/private/inquiry", {
      user_email: userEmail,
      subject: textSubject,
      content: textContents,
    });
  };

  const [isSubmitCheckModalOpen, setSubmitCheckModalIsOpen] = useState(false);
  const [isSubmitCompletedModalOpen, setSubmitCompletedModalIsOpen] =
    useState(false);

  /* 제출할건지 한번 더 묻는 모달창 */
  const handleOpenSubmitCheckModal = (e) => {
    e.preventDefault();
    // 필드값 체크하는 유효성 검사
    if (!validateForm(userEmail, textSubject, textContents)) {
      return;
    }
    // 유효성 검사를 통과할 경우 묻는 팝업 띄우기
    setSubmitCheckModalIsOpen(true);
  };

  const handleCloseSubmitCheckModal = () => {
    setSubmitCheckModalIsOpen(false);
  };
  // 사용자가 문의를 제출하겠다고 눌렀을 경우
  const handleConfirmSubmitCheckModal = () => {
    postInquiryToServer(); // 문의 내용 제출
    setSubmitCheckModalIsOpen(false); // 묻는 모달 닫기
    setSubmitCompletedModalIsOpen(true); // 문의 내용 제출 완료 모달 보여주기
  };

  /* 제출 완료 알려주는 모달창 */
  const handleCloseSubmitCompletedModal = () => {
    setSubmitCompletedModalIsOpen(false); //  제출 묻는 모달창 닫고
    onCloseInquiry(); // 문의하기 모달창도 닫기 // onClose를 onCloseInquiry로 바꾼 이유
  };

  const validateForm = (userEmail, textSubject, textContents) => {
    // 입력 필드가 비어 있는지 검사
    if (!userEmail || !textSubject || !textContents) {
      alert("모든 칸을 채워주세요.");
      return false;
    }

    // 이메일 유효성 검사
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(userEmail)) {
      alert("이메일 형식을 지켜서 입력해주세요.");
      return false;
    }

    return true;
  };

  return (
    <ModalBasic
      isOpen={isOpen}
      onClose={onCloseInquiry}
      width={window.innerWidth > 599 ? "550px" : "312px"}
      height={window.innerWidth > 599 ? "770px" : "600px"}>
      {/* Hr이 모달창 양끝에 닿게하기 위해 WrapperHeader HrStyles WrapperContents 구조로 변경 */}
      <WrapperHeader>
        <Header>Lawlow에 문의 남기기</Header>
        <ButtonStyles onClick={onCloseInquiry}>
          <img src={close_button} alt="close-button" />
        </ButtonStyles>
      </WrapperHeader>

      <HrStyles />

      <WrapperContents>
        <Contents>
          <Desktop>
            <Text color="#000" size="14px" weight="600" height="22px">
              안녕하세요, Lawlow운영팀입니다. <br />
              서비스를 이용하시면서 불편을 겪으셨거나, 개선의견을 남기고
              싶으신가요?
              <br />
              아래 양식에 따라 서비스 이용과 관련된 의견을 남겨주세요. <br />
              작성해주신 의견은 운영팀에서 확인하여 더 나은 서비스 제공을 위해
              활용됩니다.
            </Text>
          </Desktop>
          <Mobile>
            <Text color="#000" size="12px" weight="600" height="20px">
              안녕하세요, Lawlow운영팀입니다. <br />
              서비스를 이용하시면서 불편을 겪으셨거나, 개선의견을 남기고
              싶으시다면 아래 양식에 따라 의견을 남겨주세요. 작성해주신 의견은
              운영팀에서 확인하여 더 나은 서비스 제공을 위해 활용됩니다.
            </Text>
          </Mobile>

          <Desktop>
            <HeightInterval height={16} />
          </Desktop>

          <Mobile>
            <HeightInterval height={8} />
          </Mobile>

          <Desktop>
            <SubText>
              *불편사항 및 개선의견의 경우 개별 답변을 드리기 어려운 점 양해
              부탁드립니다.
              <br />
              *결제 취소 및 환불 요청은 고객 센터 확인 후 가급적 빠르게
              안내드리고 있습니다.
              <br />
              *그 밖의 서비스 이용 문의의 경우 영업일 기준 10일 이내 처리하여
              남겨주신 메일로 회신드립니다.
            </SubText>
          </Desktop>

          <Mobile>
            <SubText>
              *불편사항 및 개선의견은 개별 답변이 어려운 점 양해 부탁드립니다.
              <br />
              *결제취소 및 환불요청은 확인 후 가급적 빠르게 안내드리고 있습니다.
              <br />
              *그 밖의 서비스 이용 문의는 영업일 기준 10일 이내 처리하여
              남겨주신 메일로 회신드립니다.
            </SubText>
          </Mobile>
        </Contents>

        <Desktop>
          <HeightInterval height={32} />
          <HrStyles />
          <HeightInterval height={32} />
        </Desktop>

        <Mobile>
          <HeightInterval height={20} />
        </Mobile>

        <WrapperForm>
          <Input
            type="email" // 이메일 입력을 위한 타입으로 변경
            placeholder="답변 받을 이메일을 입력하세요."
            onChange={(e) => setUserEmail(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="제목을 입력하세요."
            onChange={(e) => setTextSubject(e.target.value)}
            required
          />
          <WrapperTextarea>
            <Textarea
              placeholder="내용을 입력하세요."
              onChange={(e) => setTextContents(e.target.value)}
              required
            />
          </WrapperTextarea>

          <ModalSubmitCheck
            isOpen={isSubmitCheckModalOpen}
            onClose={handleCloseSubmitCheckModal}
            onConfirm={handleConfirmSubmitCheckModal}
          />
          <ModalSubmitCompleted
            isOpen={isSubmitCompletedModalOpen}
            onClose={handleCloseSubmitCompletedModal}
          />
          <Button type="button" onClick={(e) => handleOpenSubmitCheckModal(e)}>
            제출하기
          </Button>
        </WrapperForm>
      </WrapperContents>
    </ModalBasic>
  );
};

const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 24px 25px 24px;

  @media (max-width: 599px) {
    margin: 20px 20px 17px 20px;
  }
`;

const HrStyles = styled.hr`
  width: 99.5%; // 100%로 하면 가로세로 스크롤이 생김
  padding: 0;
  margin: 0;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
`;

const WrapperContents = styled.div`
  margin: 32px 32px 34px;

  @media (max-width: 599px) {
    margin: 20px 20px 24px;
  }
`;

// Lawlow에 문의 남기기
const WrapperForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* 텍스트 중앙에 배치하기 */
  width: 100%;
  text-align: center;
  display: inline-block;

  @media (max-width: 599px) {
    font-size: 16px;
  }
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 599px) {
    font-size: 10px;
    line-height: 18px;
  }
`;

const ButtonStyles = styled.div`
  border: none;
  background: transparent;
  display: flex;
  position: absolute;
  right: 24px;
  width: 32px;
  height: 32px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 599px) {
    right: 20px;
    width: 24px;
    height: 24px;
  }
`;

const Input = styled.input`
  width: 486px;
  height: 48px;
  border-radius: 8px;
  border: none;
  background: #f9f9f9;
  margin-bottom: 12px;
  color: #000;
  font-family: Pretendard;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 13px 24px; // 텍스트 시작위치 설정
  box-sizing: border-box;

  &::placeholder {
    color: rgba(0, 0, 0, 0.3); /* placeholder 텍스트 색상 */
  }

  &:focus {
    outline: none; /* 포커스(선택)될 때 검은 테두리 제거 */
  }

  @media (max-width: 599px) {
    width: 272px;
    height: 42px;
    font-size: 12px;
    padding: 10px 12px;
    margin: 0px 20px 10px 20px;
  }
`;

const WrapperTextarea = styled.div`
  width: 486px;  
  height: 201px; 
  border-radius: 8px;
  background: #f9f9f9;
  box-sizing: border-box;
  padding: 16px 24px; // 추가 : 위아래 16px, 좌우 24px 패딩 추가

  @media (max-width: 599px) {
    width: 272px;
    height: 129px;
    padding: 10px 12px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  background: transparent;
  border: none;
  resize: none; // textarea의 resize 크기 조절 아이콘 없애기
  padding: 0;
  margin: 0;

  &::placeholder {
    color: rgba(0, 0, 0, 0.3); /* placeholder 텍스트 색상 */
  }

  &:focus {
    outline: none; // 포커스(선택)될 때 검은 테두리 제거
  }

  /* 스크롤바 스타일링 */
  &::-webkit-scrollbar {
    width: 10px; /* 스크롤바의 전체 너비를 설정 */
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05); /* 스크롤바 막대 색상 */
    border-radius: 4px;
    border: 2px solid #f9f9f9; /* 스크롤바와 내용 사이에 여백을 주기 위한 테두리 */
  }
  &::-webkit-scrollbar-track {
    background-color: #f9f9f9; /* 스크롤바 트랙 색상 */
    margin: 8px 0; /* 스크롤 위로부터 24px 맞추기 위해 margin-top 16px과 합작 */
  }

  @media (max-width: 599px) {
    margin: 0; // 추가 : 모바일에서도 margin을 0으로 설정
    height: calc(
      100% - 20px
    ); // 변경 : height를 모바일 패딩 값을 제외한 100%로 설정
  }
`;

const Button = styled.button`
  width: 200px;
  height: 42px;
  margin-top: 32px; /* 위 input의 padding 16과 합쳐서 32px 이 되기위해*/

  border-radius: 8px;
  background: linear-gradient(
    93deg,
    #00f370 14.47%,
    #00c2de 51.58%,
    #0057ff 97.39%
  );

  color: white;
  border: none;
  cursor: pointer;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;

  @media (max-width: 599px) {
    margin-top: 20px;
  }
`;

export default ModalInquiry;
