import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import ModalDeleteCheck from "../Components/Modal/ModalDeleteCheck.jsx";
import trash from "../img/trash.png";

const Log = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goToQueryDetail = () => {
    navigate(`/main/q/${props.id}`); // 페이지 이동 /main/로그상세/id
  };

  const [isDeleteModalOpen, setDeleteModalIsOpen] = useState(false);
  const handleOpenDeleteCheckModal = () => {
    setDeleteModalIsOpen(true);
  };
  const handleCloseDeleteCheckModal = () => {
    setDeleteModalIsOpen(false);
  };

  // URL에서 query ID 가져오기
  const queryIdFromUrl = location.pathname.split("/").pop();
  const isActive = queryIdFromUrl && Number(queryIdFromUrl) === props.id;

  return (
    <Wrapper isActive={isActive} onClick={goToQueryDetail}>
      <Content>{props.content}</Content>
      <ModalDeleteCheck
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDeleteCheckModal}
        title="질문 로그 삭제 재확인"
        logContent={props.content}
        logId={props.id}
      />
      <HoverButton>
        <HoverImg
          src={trash}
          alt="버튼 이미지"
          onClick={() => handleOpenDeleteCheckModal()}
        />
      </HoverButton>
    </Wrapper>
  );
};

const HoverButton = styled.div`
  border-radius: 50%; /* 동그란 모양 */
  flex-shrink: 0;
  display: none; /* 기본적으로 숨김 */

  /* 데스크톱 환경에서 호버 시 보이도록 설정 */
  @media (max-width: 932px) {
    display: block;
  }
`;

const Wrapper = styled.div`
  width: 240px;
  max-height: 72px;
  color: ${(props) =>
    props.isActive
      ? "black"
      : "rgba(0, 0, 0, 0.3)"}; // 사용자가 조회한 질문을 검은색으로 변경
  text-align: justify;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  margin-left: 32px;
  position: relative; /* 상대적 위치 설정 */
  display: flex;
  align-items: center; /* 세로 중앙 정렬 */

  &:hover {
    color: black;
    cursor: pointer;

    /* 데스크톱 환경에서 HoverButton 보이게 하기 */
    ${HoverButton} {
      display: block;
    }
  }
`;
const Content = styled.div`
  text-overflow: ellipsis;
  overflow: hidden; // 내용이 넘칠 경우 숨김
  display: -webkit-box; // 웹킷 브라우저를 위한 박스 모델 디스플레이
  -webkit-box-orient: vertical; // 박스의 내용을 수직으로 정렬
  -webkit-line-clamp: 3; // 텍스트를 최대 3줄까지 표시
  margin-right: 8px; // 쓰레기(trash) 버튼 HoverButton과의 간격을 8px로 설정
`;

const HoverImg = styled.img`
  width: 24px;
  height: 24px;
  position: absolute; /* 절대 위치 */
  transform: translateY(-50%); /* Y축 기준 50%만큼 위로 이동 */
`;

export default Log;
