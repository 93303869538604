import React from "react";
import styled, { css } from "styled-components";

const Button = ({
  image_src,
  alt_text,
  button_text,
  paddingRight,
  fontColor,
  fontSize,
  fontWeight,
  lineHeight,
  onClick,
  width = "auto",
  height = "auto",
  imgWidth = "auto",
  imgHeight = "auto",
  disabled,
  gap,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      width={width}
      height={height}
      gap={gap}
      disabled={disabled}>
      <ButtonImg
        src={image_src}
        alt={alt_text}
        imgWidth={imgWidth}
        imgHeight={imgHeight}
      />
      {button_text && (
        <ButtonText
          fontColor={fontColor}
          fontSize={fontSize}
          fontWeight={fontWeight}
          lineHeight={lineHeight}
          paddingRight={paddingRight}>
          {button_text}
        </ButtonText>
      )}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  /* 기본 버튼 스타일링 */
  padding: 0;
  background-color: transparent; /* 버튼 기본 배경색 투명하게 설정*/
  width: ${(props) => (props.width === "auto" ? "auto" : `${props.width}`)};
  height: ${(props) => (props.height === "auto" ? "auto" : `${props.height}`)};
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease; /* 배경색 전환 트랜지션 */
  /* 호버 효과 */
  &:hover {
    background-color: rgba(
      0,
      0,
      0,
      0.1
    ); /* 호버 시 얕은 회색으로 배경색 변경 */
    cursor: pointer; /* 커서 모양을 손가락으로 변경 */
  }
  /* 자식 요소 수직 수평 가운데 정렬 */
  ${(props) =>
    !props.hasText &&
    css`
      display: inline-block; /* button_text가 없을 때만 inline-block으로 변경 */
    `}
  display:flex;
  align-items: center;
  ${(props) =>
    props.gap !== undefined
      ? `gap: ${props.gap}px;`
      : ""} /* StyledButton과 ButtonImg 사이 간격 */

  /* ToggleDropdown을 위해 disabled 스타일링 추가 */
  ${({ disabled }) =>
    disabled
      ? `
        opacity: 0.5;
        pointer-events: none;
        & img {
          opacity: 0.3; /* 이미지 색상을 rgba(0, 0, 0, 0.30)로 변경 */
        }
      `
      : `
        opacity: 1;
        pointer-events: auto;
        & img {
          opacity: 1;
        }
      `}
`;

const ButtonImg = styled.img`
  width: ${(props) =>
    props.imgWidth === "auto" ? "auto" : `${props.imgWidth}px`};
  height: ${(props) =>
    props.imgHeight === "auto" ? "auto" : `${props.imgHeight}px`};
  flex-shrink: 0;
`;

const ButtonText = styled.span`
  font-family: Pretendard;
  font-style: normal;
  color: ${(props) => props.fontColor || "rgba(0, 0, 0, 0.6)"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "600"};
  line-height: ${(props) => props.lineHeight || "26px"};
  padding-right: ${(props) =>
    props.paddingRight || "0px"}; /* 클릭 범위 설정 image src의 크기 고려 */
`;

export default Button;
