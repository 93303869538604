// 개인정보 처리방침
import React from "react";
import styled from "styled-components";
import privacyPolicyDataRaw from "../data/privacyPolicy.json";

const privacyPolicyData = JSON.parse(
  JSON.stringify(privacyPolicyDataRaw),
  (key, value) =>
    typeof value === "string" ? value.replace(/\\n/g, "<br>") : value
);

const PrivacyPolicy = () => {
  return (
    <>
      <RuleWrapper>
        {privacyPolicyData.rules.map((rule, index) => (
          <div key={index}>
            <Title hasId={!!rule.rule_id}>{rule.rule_title}</Title>
            {rule.contents.map((content, index) => (
              <div key={index}>
                <Content
                  dangerouslySetInnerHTML={{ __html: content.main_content }}
                />
                {content.sub_contents && content.sub_contents.length > 0 && (
                  <StyledUl>
                    {content.sub_contents.map((subContent, subIndex) => (
                      <li key={subIndex}>
                        <Content
                          dangerouslySetInnerHTML={{ __html: subContent }}
                        />
                      </li>
                    ))}
                  </StyledUl>
                )}
              </div>
            ))}
          </div>
        ))}
      </RuleWrapper>
    </>
  );
};

const RuleWrapper = styled.div`
  counter-reset: rule-counter;
  padding-left: 10px; // 왼쪽 여백 추가
`;

const Title = styled.div`
  color: ${(props) => (props.hasId ? "rgba(0, 0, 0, 0.60)" : "#000")};
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 8px;

  &:before {
    content: ${(props) => (props.hasId ? "counter(rule-counter) '. '" : "''")};
    counter-increment: ${(props) => (props.hasId ? "rule-counter" : "none")};
  }
`;

const Content = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledUl = styled.ul`
  padding-left: 16px;
  margin: 0;
  margin-left: 5px;
`;

export default PrivacyPolicy;
