import React from "react";
import styled from "styled-components";
import ModalBasic from "./ModalBasic.jsx";
import shareIconIos from "../../img/shareIconIos.png";

const ModalIOSAddToHomeScreen = ({ isOpen, onClose }) => {
  const handleConfirm = () => {
    onClose();
  };

  return (
    // PWA 설치 모달은 Mobile 사이즈만 존재
    <ModalBasic
      isOpen={isOpen}
      onClose={onClose}
      width={window.innerWidth > 430 ? "400px" : "312px"}
      height={window.innerWidth > 430 ? "178px" : "157px"}>
      <ContentWrapper>
        <TextWrapper>
          상단 링크 혹은 하단 탭의 <Icon src={shareIconIos} alt="home icon" />
          모양을 눌러
          <br /> 홈 화면에 추가하기를 통해 설치해주세요.
        </TextWrapper>
        <ButtonWrapper>
          <ButtonStyles
            type="confirm"
            onClick={handleConfirm}
            style={{ color: "#00F370" }}>
            확인
          </ButtonStyles>
        </ButtonWrapper>
      </ContentWrapper>
    </ModalBasic>
  );
};
const ContentWrapper = styled.div`
  margin: 26px 24px 23px 24px;

  @media (max-width: 430px) {
    margin: 24px;
  }
`;

const TextWrapper = styled.div`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  @media (max-width: 430px) {
    font-size: 16px;
    line-height: 26px;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  vertical-align: text-bottom;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 18px;

  @media (max-width: 430px) {
    margin-top: 16px;
  }
`;

const ButtonStyles = styled.button`
  margin-left: 8px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 6px;
  background: ${(props) => (props.type === "cancel" ? "#F2F2F2" : "#000")};
  color: ${(props) => (props.type === "cancel" ? "#000" : "#FFF")};
  width: 70px;
  height: 40px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;

  &:disabled {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.3);
    cursor: not-allowed;
  }
`;

export default ModalIOSAddToHomeScreen;
