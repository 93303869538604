import React, { useState, useEffect } from "react";
import styled from "styled-components";
import dummyData from "../data/dummyData.json"; // 테스트용 더미 데이터

const PayList = ({ userLawList }) => {
  // userLawList = dummyData;

  const [groupedByYear, setGroupedByYear] = useState({});

  useEffect(() => {
    const groupByYear = userLawList.reduce((acc, credit) => {
      const year = new Date(credit.createdAt).getFullYear();
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(credit);
      return acc;
    }, {});

    // 각 연도의 항목들을 내림차순으로 정렬
    Object.keys(groupByYear).forEach((year) => {
      groupByYear[year].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    });

    setGroupedByYear(groupByYear);
  }, [userLawList]);

  return (
    <Wrapper>
      {Object.keys(groupedByYear)
        .sort((a, b) => b - a) // 연도를 내림차순으로 정렬
        .map((year) => (
          <BoxList key={year}>
            <YearTextStyle>{`<${year}>`}</YearTextStyle>
            <StyledUl>
              {groupedByYear[year].map((credit, index) => (
                <StyledListItem key={index}>
                  <TextWrapper>
                    <DateTextStyle>
                      {credit.createdAt.substring(5, 10).replace("-", "/")}
                    </DateTextStyle>
                    <MainTextStyle>{credit.mainContents}</MainTextStyle>
                    <AmountTextStyle>
                      {Math.abs(credit.amount)}
                      <SignTextStyle amount={credit.amount}>
                        {credit.amount > 0 ? "+" : "-"}
                      </SignTextStyle>
                    </AmountTextStyle>
                  </TextWrapper>
                  {credit.subContents && (
                    <SubTextStyle>{credit.subContents}</SubTextStyle>
                  )}
                </StyledListItem>
              ))}
            </StyledUl>
          </BoxList>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 277px;
  // margin-top: 12px; /* 2024 년도에서 margin-top 12px을 해놔서 여기는 필요없음*/
  margin-bottom: 4px;
  overflow-y: auto; /* 로우 내역 스크롤 생성 */

  /* 스크롤바 스타일링 */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
`;

const BoxList = styled.div``;

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 13px 0px 0px 0px;
`;

const StyledListItem = styled.li`
  padding: 4px 0px; /* 월일 내역 사이 간격 8px(위아래 4px씩)*/
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const YearTextStyle = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: bold;
  margin-top: 12px;
`;

const DateTextStyle = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 500;
`;

const AmountTextStyle = styled.span`
  color: #000;
  font-size: 12px;
  font-weight: 700;
`;

const SignTextStyle = styled.span`
  color: ${(props) => (props.amount > 0 ? "#00F370" : "#FF3E3E")};
  margin-left: 3px;
  display: inline-block; // span에 width 적용을 위함
  width: 16px;
`;

const MainTextStyle = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 500;
  width: 70%;
`;

const SubTextStyle = styled.span`
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-weight: 400;
  display: flex;

  margin-left: 41px;
  margin-top: 4px;
`;

export default PayList;
