import React from "react";
import styled from "styled-components";
import Info from "../Components/Info";
import SituationBox from "../Components/SituationBox";
import AskForm from "../Components/AskForm";
import HeightInterval from "../Components/HeightInterval.jsx";
import exampleCases from "../data/exampleCases.json";

const Ask = ({ initialTextValue }) => {
  return (
    <Wrapper>
      <LeftAlignedContainer>
        <HeightInterval minHeight={20} maxHeight={36} />
        <Info />
      </LeftAlignedContainer>
      <HeightInterval minHeight={20} maxHeight={40} />
      <SituationBox
        title="예시)"
        titleFontSize={18}
        contents={exampleCases.example}
        intervalHeight={16}
      />
      {/* 다시 시도 버튼 임시로 없앰 */}
      <HeightInterval minHeight={20} maxHeight={40} />
      <AskForm initialTextValue={initialTextValue} />
      {/* AskForm에 props로 전달 */}
      <Notice>
        *기재한 내용은 AI 학습 데이터로 수집 및 활용되지 않습니다.
      </Notice>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Notice = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 12px;
`;

const LeftAlignedContainer = styled.div``;

export default Ask;
