// 이용약관
import React from "react";
import styled from "styled-components";
import termsOfServiceDataRaw from "../data/termsOfService.json";

const termsOfServiceData = JSON.parse(
  JSON.stringify(termsOfServiceDataRaw),
  (key, value) =>
    typeof value === "string" ? value.replace(/\\n/g, "<br>") : value
);

const TermsOfService = () => {
  return (
    <>
      <RuleWrapper>
        <Title>[이용약관]</Title>
        {termsOfServiceData.rules.map((rule, index) => (
          <div key={index}>
            <Title hasId={!!rule.rule_id}>{rule.rule_title}</Title>
            {rule.subtitles.map((subtitle, index) => (
              <div key={index}>
                <Subtitle>{subtitle.title}</Subtitle>
                <Content
                  dangerouslySetInnerHTML={{ __html: subtitle.content }}
                />
              </div>
            ))}
          </div>
        ))}
        <Title>[부칙]</Title>
        <Content>
          본 약관은 2024년 2월 1일부터 13일간 공지하고, 2024년 2월 15일부터
          시행한다.
        </Content>
        <Title>변경 이력</Title>
        <Content>2024년 2월 1일 개정</Content>
      </RuleWrapper>
    </>
  );
};

const RuleWrapper = styled.div`
  counter-reset: rule-counter;
  padding-left: 10px; // 왼쪽 여백 추가
`;

const Title = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 8px;
`;

const Subtitle = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const Content = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export default TermsOfService;
