import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import HeightInterval from "../Components/HeightInterval.jsx";
import Button from "../Components/Button.jsx";
import ModalUnauthorizedUser from "./Modal/ModalUnauthorizedUser.jsx";
import useStore from "../store.js";
import config from "../config.js";
import copy from "../img/copy.png";
import search from "../img/search.png";
import { Desktop, Mobile } from "../MediaQuery/OnboardingPageMediaQuery.js";
import ai from "../img/ai.png";
import dots_png from "../img/dots_png.png";
import dots_gif from "../img/dots_gif.gif";
import { useNavigate } from "react-router-dom";

const CaseComparison = ({
  caseId,
  isComparison,
  commonality,
  difference,
  setLoading,
  onCopy, // handleCopy 함수를 props로 받음
  onSearch, // handleSearch 함수를 props로 받음
}) => {
  const [comparison, setComparison] = useState(null);
  const [wrapperHeight, setWrapperHeight] = useState("52px");
  const [isLoading, setIsLoading] = useState(false); // Mobile의 isLoading 상태 변경
  const [retryCount, setRetryCount] = useState(0); // 재시도 횟수 추가
  const [iconBottom, setIconBottom] = useState("17px"); // 아이콘이 bottom으로부터 떨어진 정도
  const [isMobileShort, setIsMobileShort] = useState(false); // Mobile 범위에서 isShort한지 판단
  const [isPCShort, setIsPCShort] = useState(false); // PC 범위에서 isShort한지 판단
  const [isFetching, setIsFetching] = useState(false); // 서버로 fetching 하고있는 걸 알 수 있는 상태 추가
  const [requestQueue, setRequestQueue] = useState([]); // 요청이 완료된 후, requestQueue에 대기 중인 다음 요청이 있으면 이를 실행
  const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false); // Unauthorized 모달창 상태 변수

  const navigate = useNavigate();
  const initialRender = useRef(true);
  const {
    updateUserInfo,
    updateUserLaw,
    deductUserLawOptimistic,
    restoreUserLaw,
    deleteCookieByKey,
  } = useStore();

  // REACT_APP_SERVER_DOMAIN_DEV
  // REACT_APP_SERVER_DOMAIN_DEPLOY

  useEffect(() => {
    if (!caseId) return; // id가 없다면 실행하지 않음
    // const SERVER_URI = process.env.REACT_APP_SERVER_DOMAIN_DEPLOY;
    const SERVER_URI = config.serverUri;
    const uri = `/private/query/comparison/${caseId}`;
    const url = `${SERVER_URI}${uri}`;

    // 비교하기 기능
    const fetchLog = async () => {
      if (isFetching) return; // 이미 서버측에 fetching 중이면 fetchLog 실행하지 않음
      setIsFetching(true);
      let previousLaw; // optimistic update를 적용하기 이전의 현재 로우
      try {
        previousLaw = deductUserLawOptimistic(2); // 사용자가 차감하려는 로우 양
        const response = await fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch Comparison");
        }

        const data = await response.json();
        const formattedData = {
          ...data,
          commonality: data.commonality.replace(/\\n/g, "<br />"),
          difference: data.difference.replace(/\\n/g, "<br />"),
        };
        setComparison(formattedData);
        // 로우 차감 후 마이페이지 정보 업데이트를 위해 store 사용자 정보와 로우 내역 업데이트
        await updateUserInfo(setShowUnauthorizedModal);
        await updateUserLaw();
      } catch (error) {
        console.error("Error fetching Comparison:", error);
        if (retryCount < 3) {
          // 재시도 횟수 제한
          setIsFetching(false); // isFetching를 false 만든 후에
          setRetryCount(retryCount + 1); // retryCount 상태 변화 시켜서 fetchLog 재실행해야한다
        }
        restoreUserLaw(previousLaw); // 서버 요청 실패 시 로우 복원
      } finally {
        setIsFetching(false);
        if (requestQueue.length > 0) {
          const nextRequest = requestQueue.shift();
          setRequestQueue(requestQueue); // 큐 업데이트
          nextRequest(); // 다음 요청 실행
        }
      }
    };

    const handleFetchLog = () => {
      // 서버측에 Fetching 중인지 확인 후 fetchLog 함수 실행
      if (isFetching) {
        setRequestQueue((prevQueue) => [...prevQueue, fetchLog]);
      } else {
        fetchLog();
      }
    };

    if (!isComparison) {
      // 비교하기가 처음일 경우 비교 데이터 서버 요청
      setLoading(true); // Desktop loading 상태 변경
      setIsLoading(true); // Mobile loading 상태 변경
      handleFetchLog(); // 비교하기 쿼리 날리기
    } else {
      // 이미 비교 결제 기록이 있다면
      const formattedData = {
        commonality: commonality.replace(/\\n/g, "<br />"),
        difference: difference.replace(/\\n/g, "<br />"),
      };
      setComparison(formattedData);
    }
  }, [caseId, retryCount]); // 질문 query Id 혹은 재시도 횟수 변화시 실행

  useLayoutEffect(() => {
    if (comparison && comparison.commonality) {
      setLoading(false); // 데이터 로드 완료 후 로딩 상태 업데이트 보장
      setIsLoading(false); // Mobile loading 상태 변경
      setWrapperHeight("auto"); // 데이터 로드 완료 후 Wrapper height 변경

      const wrapperElement = document.getElementById("wrapper");

      // requestAnimationFrame을 사용하여 DOM 업데이트 후 실행
      requestAnimationFrame(() => {
        const height = wrapperElement ? wrapperElement.clientHeight : 0;

        // console.log("height : ", height);

        setIsMobileShort(height <= 256); // 1) Mobile 범위(~ 1439px)의 height설정 - height가 256px 이하(각 3줄씩. Mobile에서 최소인 케이스)일 경우 true로 설정
        setIsPCShort(height <= 274); // 2) PC/Tablet 범위(1141px ~ 1440px)의 height 설정 - height가 274px 이하(각 3줄씩. PC/태블릿에서 최소인 케이스)일 경우 true로 설정

        // 3) PC/Tablet 범위(1441px ~ 10000px)의 height설정
        // 비교하기 Wrapper의 height가 298px 이상(공통점 + 차이점 합 최소 8줄 이상)을 기준으로 설정
        if (height >= 298) {
          setIconBottom("52px");
        } else if (height == 256) {
          setIconBottom("28px");
        } else {
          setIconBottom("17px");
        }
      });
    }
  }, [comparison, setLoading]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false; // 첫 렌더링 후에 false로 설정
      return;
    }

    if (!showUnauthorizedModal) {
      // Unauthorized 모달이 닫힐 때 로그인 페이지로 리디렉션 수행
      navigate("/");
    }
  }, [showUnauthorizedModal]);

  return (
    <>
      <Desktop>
        <Wrapper id="wrapper" height={wrapperHeight}>
          {comparison && (
            <>
              <IconContainer isPCShort={isPCShort} bottom={iconBottom}>
                <Button
                  image_src={copy}
                  alt_text="복사하기"
                  imgWidth={32}
                  imgHeight={32}
                  onClick={onCopy}></Button>
                <Button
                  image_src={search}
                  alt_text="원문검색"
                  imgWidth={32}
                  imgHeight={32}
                  onClick={onSearch}></Button>
              </IconContainer>

              <HeightInterval height={42} />

              <Summary>
                <Title>공통점</Title>
                <Contents
                  dangerouslySetInnerHTML={{ __html: comparison.commonality }}
                />
              </Summary>

              <HeightInterval height={16} />

              <Summary>
                <Title>차이점</Title>
                <Contents
                  dangerouslySetInnerHTML={{ __html: comparison.difference }}
                />
              </Summary>

              <HeightInterval height={16} />
              <AlertContents>
                *해당 내용은 AI의 작성으로 인한 실수가 생길 수 있습니다.
              </AlertContents>
              <HeightInterval height={24} />
            </>
          )}
        </Wrapper>
      </Desktop>

      <Mobile>
        <Wrapper>
          <Overlay>
            <AiDiv>
              <img src={ai} width="21px" height="21px" alt="AI" />
              <AiText>내 상황과 비교하기</AiText>
              {isLoading ? (
                <img src={dots_gif} width="22px" height="22px" alt="Dots Gif" />
              ) : (
                <img src={dots_png} width="22px" height="22px" alt="Dots Png" />
              )}
            </AiDiv>
          </Overlay>
          <HeightInterval height={36} />
          {comparison && (
            <>
              <IconContainer isMobileShort={isMobileShort}>
                <Button
                  image_src={copy}
                  alt_text="복사하기"
                  imgWidth={32}
                  imgHeight={32}
                  onClick={onCopy}></Button>
                <Button
                  image_src={search}
                  alt_text="원문검색"
                  imgWidth={32}
                  imgHeight={32}
                  onClick={onSearch}></Button>
              </IconContainer>
              <Summary>
                <Title>공통점</Title>
                <Contents
                  dangerouslySetInnerHTML={{ __html: comparison.commonality }}
                />
              </Summary>

              <HeightInterval height={16} />

              <Summary>
                <Title>차이점</Title>
                <Contents
                  dangerouslySetInnerHTML={{ __html: comparison.difference }}
                />
              </Summary>

              <HeightInterval height={24} />
              <AlertContents>
                *해당 내용은 AI의 작성으로 인한 실수가 생길 수 있습니다.
              </AlertContents>
              <HeightInterval height={16} />
            </>
          )}
        </Wrapper>
      </Mobile>
      {showUnauthorizedModal && (
        <ModalUnauthorizedUser
          isOpen={showUnauthorizedModal}
          onClose={() => setShowUnauthorizedModal(false)}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  height: ${(props) => props.height};
  width: 100%;
  background: linear-gradient(
    107deg,
    rgba(0, 243, 112, 0.04) 8.55%,
    rgba(0, 87, 255, 0.04) 73.74%
  );
  position: relative;
  /* 왼쪽 오른쪽 아래 반경 설정 */
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`;

const IconContainer = styled.div`
  // PC&Tablet 범위(1441px ~ 10000px)
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 24px;
  // PC, Tablet은 height에 따라 동적으로 변경
  // bottom: ${(props) => props.bottom}; // props로 받은 bottom 값 적용
  bottom: 28px; // 임시
  z-index: 10; // Wrapper와 다른 내용물 위에 올라오도록 z-index 설정
  height: 80px;

  img {
    margin-bottom: 8px;
  }

  /* PC&Tablet 범위 */
  // height가 274px(각3줄) 이하일 경우 32px
  @media (max-width: 1440px) and (min-width: 701px) {
    height: auto;
    // bottom: ${(props) => (props.isPCShort ? "32px" : "60px")};
    bottom: 17px; // 임시
  }

  /* Mobile 범위 */
  // 제일 Width가 작은 iPad Mini 기종의 CSS 픽셀이 W 756 x H 1134 라서 임의로 700px 잡음
  // height가 256px(각3줄) 이하일 경우 32px
  @media (max-width: 700px) {
    height: auto;
    // bottom: ${(props) => (props.isMobileShort ? "28px" : "54px")};
    bottom: 58px; // 임시
  }
`;

const Summary = styled.div`
  width: auto;
  height: auto;
  display: flex;
  margin-left: 18px;
  margin-right: 32px;

  /* 모바일 환경 */
  @media (max-width: 932px) {
    width: auto;
    margin-left: 18px;
    margin-right: 18px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  min-width: 40px;
  margin-top: 2.5px;
  margin-right: 10px;

  /* 모바일 환경 */
  @media (max-width: 932px) {
    width: auto;
  }
`;

const Contents = styled.div`
  color: #000;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.16px;
  width: auto;

  /* 모바일 환경 */
  @media (max-width: 932px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

const AlertContents = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-left: 73px;

  // 제일 Width가 작은 iPad Mini 기종의 CSS 픽셀이 W 756 x H 1134 라서 임의로 700px 잡음
  /* Mobile 범위 */
  @media (max-width: 700px) {
    width: calc(100% - 40px);
    margin: 0 19px;
  }
`;

const AiHrStylesImg = styled.img`
  width: 133px;
  height: 21px;
  margin-left: 18px;
`;

const AiHrStyles = styled.hr`
  border: none;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 1px #f1f1f1;
  margin: 0;
  margin-top: 17.5px; /* AiDiv 높이의 절반만큼 조정 */
`;

const Overlay = styled.div`
  position: absolute; /* 부모 요소를 기준으로 위치 설정 */
  left: 50%;
  top: -17.5px; /* AiHrStyles 위로 이동하도록 조정 */
  transform: translateX(-50%);
  z-index: 30; /* ContentContainer의 z-index보다 값이 커야함 */
`;

const AiDiv = styled.div`
  width: 158px;
  height: 32px;
  border-radius: 24px;
  border: 1px solid #f1f1f1;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 18px;
`;

const AiText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-left: 6px;
`;

export default CaseComparison;
