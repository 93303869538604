import React from "react";
import styled from "styled-components";
import HeightInterval from "../Components/HeightInterval.jsx";
import alert from "../img/alert.png";
import { Desktop, Mobile } from "../MediaQuery/OnboardingPageMediaQuery.js";

const Alert = () => {
  return (
    <Wrapper>
      <StyledImg src={alert} />

      <StyledText>
        <Desktop>
          <HeightInterval height={10} />
          각 결과 내 ‘문제’와 ‘결론’은 AI의 작성으로 인한 실수가 생길 수
          있습니다.
          <br />
          ‘내용’을 통해 법원에서 제공되는 정확한 판례 내용을 확인해보세요.
        </Desktop>
        <Mobile>
          <HeightInterval height={8} />
          각 결과 내 ‘문제’와 ‘결론’은 AI의 작성으로 인한
          <br />
          실수가 생길 수 있습니다.‘내용’을 통해 법원에서
          <br />
          제공되는 정확한 판례 내용을 확인해보세요.
        </Mobile>
      </StyledText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

const StyledText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export default Alert;
