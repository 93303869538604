import React, { useState } from "react";
import styled from "styled-components";
import ModalBasic from "../Modal/ModalBasic.jsx";
import ModalPayment from "./ModalPayment.jsx";

// PWA 웹 앱 설치 묻는 모달창
const ModalLawCharge = ({ isOpen, onClose }) => {
  /* 로우 충전 Modal */
  const [isPaymentModalOpen, setPaymentModalIsOpen] = useState(false); // 로우 충전 Modal
  const handleOpenPaymentModal = () => {
    setPaymentModalIsOpen(true);
  };
  const handleClosePaymentModal = () => {
    setPaymentModalIsOpen(false);
  };

  /* 로우 충전하시겠습니까? 물음에 확인을 누른 경우 */
  const handleConfirm = () => {
    onClose(); // 이 ModalLawCharge 자체 팝업창 없애고
    handleOpenPaymentModal(true); // 로우 충전 팝업창 뜨게 하기
  };

  return (
    <>
      <ModalBasic
        isOpen={isOpen}
        onClose={onClose}
        width={window.innerWidth > 430 ? "400px" : "312px"}
        height={window.innerWidth > 430 ? "130px" : "111px"}
        padding={window.innerWidth > 430 ? "24px" : "23px"}>
        <ContentWrapper>
          <TextWrapper>
            작업을 진행하기에 로우가 부족합니다.
            <br /> 로우를 충전하러 가시겠습니까?
          </TextWrapper>
          <ButtonWrapper>
            <ButtonStyles type="cancel" onClick={onClose}>
              취소
            </ButtonStyles>
            <ButtonStyles
              type="confirm"
              onClick={handleConfirm}
              style={{ color: "#00F370" }}>
              확인
            </ButtonStyles>
          </ButtonWrapper>
        </ContentWrapper>
      </ModalBasic>
      <ModalPayment
        isOpen={isPaymentModalOpen}
        onClose={handleClosePaymentModal}
        title="로우 충전하기"
      />
    </>
  );
};

const ContentWrapper = styled.div`
  color: #000;
  /* padding 24px 라는걸 전제로 조정한 margin값 */
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;

  @media (max-width: 430px) {
    /* padding 23px 라는걸 전제로 조정한 margin값 */
    margin-top: 3px;
    margin-left: 1px;
    margin-right: 1px;
  }
`;

const TextWrapper = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  @media (max-width: 430px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 18px;

  @media (max-width: 430px) {
    margin-top: 16px;
  }
`;

const ButtonStyles = styled.button`
  width: 70px;
  height: 40px;
  margin-left: 8px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 6px;
  background: ${(props) => (props.type === "cancel" ? "#F2F2F2" : "#000")};
  color: ${(props) => (props.type === "cancel" ? "#000" : "#FFF")};

  &:disabled {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.3);
    cursor: not-allowed;
  }
`;

export default ModalLawCharge;
