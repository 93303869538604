import React, { useState, useEffect, forwardRef } from "react";
import styled from "styled-components";
import { Mobile, Desktop } from "../MediaQuery/OnboardingPageMediaQuery.js";
import copyButton from "../img/copyButton.png";
import rewriteButton from "../img/rewriteButton.png";

const MAX_CHAR_COUNT = 103; // 내 상황 예시에 표시할 최대 글자 수

const SituationBox = forwardRef(
  (
    {
      title,
      contents,
      titleFontSize,
      type,
      onCopyClick,
      onRewriteClick,
      intervalHeight,
      // img_url,
    },
    ref
  ) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [displayContent, setDisplayContent] = useState(contents);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    const getShortContent = (text, limit) => {
      if (text.length <= limit) {
        return text;
      }
      return text.slice(0, limit) + "...";
    };

    useEffect(() => {
      if (!isExpanded) {
        setDisplayContent(getShortContent(contents, MAX_CHAR_COUNT));
      } else {
        setDisplayContent(contents);
      }
    }, [contents, isExpanded]);

    return (
      <WrapperBox ref={ref} contents={contents}>
        <Contents>
          <Line>
            <Text titleFontSize={titleFontSize}>{title}</Text> {/* 내 상황*/}
            {/* <Button>
            <img src={img_url} alt={buttonName} />
          </Button> */}
            {/* 문장 만들어지기 전까진 임시로 닫아두기 */}
            {type && type === "result" && (
              <ButtonWrapper>
                <ButtonImage
                  src={copyButton}
                  alt="복사하기 버튼"
                  onClick={onCopyClick}
                />
                <ButtonImage
                  src={rewriteButton}
                  alt="수정하기 버튼"
                  onClick={onRewriteClick}
                />
              </ButtonWrapper>
            )}
          </Line>

          <HeightInterval height={intervalHeight} />

          <Case>
            <Mobile>
              {displayContent}
              {contents.length > MAX_CHAR_COUNT && (
                <ToggleButton onClick={toggleExpand}>
                  {isExpanded ? "접기" : "더보기"}
                </ToggleButton>
              )}
            </Mobile>
            <Desktop>{contents}</Desktop>
          </Case>
        </Contents>
      </WrapperBox>
    );
  }
);

const WrapperBox = styled.div`
  height: auto;
  flex-shrink: 0;
  border-radius: 24px;
  background: #f9f9f9;
  overflow: hidden;

  @media (max-width: 10000px) and (min-width: 1140px) {
    width: 680px;
  }

  // 질문결과 페이지 기준도 고려됨
  @media (max-width: 1139px) and (min-width: 745px) {
    width: 680px;
  }

  @media (max-width: 744px) and (min-width: 600px) {
    width: calc(100% - 64px);
  }

  @media (max-width: 599px) {
    width: calc(100% - 48px);
  }
`;

const Contents = styled.div`
  margin: 24px 32px;
  width: auto;

  @media (max-width: 599px) {
    margin: 20px 16px;
  }
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* 버튼이 오른쪽 끝에 붙도록 자식 요소 간의 여백을 최대로 벌리기 */
`;

const Text = styled.div`
  width: 100px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: ${(props) => props.titleFontSize}px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
`;

const Button = styled.button`
  width: 32px; /* 버튼의 너비를 24px로 설정 */
  height: 32px; /* 버튼의 높이를 24px로 설정 */
  margin-bottom: 13px; /* 아래쪽 여백 추가 */
  background-size: cover;
  border: none; /* 테두리 제거 */
  border-radius: 50%; /* 동그란 모양 */
  display: flex;
  justify-content: center;
  align-item: center;

  img {
    width: 24px; /* 이미지의 너비를 버튼에 맞춤 */
    height: 24px; /* 이미지의 높이를 버튼에 맞춤 */
    padding: 3px 3px 4px 4px;
    border: none; /* 테두리 제거 */
    cursor: pointer; /* 마우스 커서를 포인터로 변경 */
  }
`;

const Case = styled.div`
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  @media (max-width: 599px) {
    font-size: 12px;
    line-height: 23px;
  }
`;

// Mobile에서 활성화
const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  margin-left: 5px;
  padding: 0;

  // 임시 처리
  @media (max-width: 10000px) and (min-width: 1141px) {
    display: none;
  }

  @media (max-width: 1140px) and (min-width: 600px) {
    font-size: 16px;
  }

  @media (max-width: 599px) {
    font-size: 14px;
  }
`;

const HeightInterval = styled.div`
  width: auto;
  height: ${({ height }) => (height ? `${height}px` : "16px")};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const ButtonImage = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export default SituationBox;
