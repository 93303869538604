import React, { useEffect } from "react";
import ModalBasic from "./ModalBasic.jsx";
import styled from "styled-components";
import close_button from "../../img/close_button.png";
import img30 from "../../img/30_img.png";
import img190 from "../../img/190_img.png";
import img400 from "../../img/400_img.png";
import axios from "axios";
import { Desktop, Mobile } from "../../MediaQuery/ModalPaymentMediaQuery.js";
import Text from "../Text.jsx";
import nextButtonImg from "../../img/nextButtonImg.svg";
import HeightInterval from "../HeightInterval.jsx";
import WidthInterval from "../WidthInterval.jsx";
import config from "../../config.js";
import { useNavigate } from "react-router-dom";
import ModalSuccessLawCharge from "../Modal/ModalSuccessLawCharge.jsx";

// BoxPrice 버튼 마우스 호버시 불투명도를 0.2로 증가
const adjustOpacity = (rgba, opacity) => {
  const parts = rgba.match(/[\d.]+/g);
  if (parts && parts.length === 4) {
    return `rgba(${parts[0]}, ${parts[1]}, ${parts[2]}, ${opacity})`;
  }
  return rgba;
};

// ModalBasic 컴포넌트를 세분화한 충전하기 모달 컴포넌트
const ModalPayment = ({ isOpen, onClose, title }) => {
  const navigate = useNavigate(); // navigate 객체 생성
  /* 포트원 SDK 설치 - IMP 라이브러리 가져오는 코드 */
  useEffect(() => {
    const iamport = document.createElement("script");
    iamport.src = "https://cdn.iamport.kr/v1/iamport.js";
    document.head.appendChild(iamport);
    return () => {
      document.head.removeChild(iamport);
    };
  }, []);

  let law = 0; // 결제하려는 로우 변수 초기화 이후 변경
  const SERVER_BASE_URL = "https://www.lawlow.info/main"; // 모바일 결제완료 후 돌아갈 URL
  // 결제 요청 함수
  const requestPay = (cost) => {
    // IMP 객체 초기화
    const { IMP } = window;

    const SERVER_URI = config.serverUri;
    const uri = `/private/payments/check`;
    const server_url = `${SERVER_URI}${uri}`;
    const merchant_uid = `mid_${new Date().getTime()}`; // 결제 고유 주문번호
    axios({
      url: server_url,
      method: "post",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      data: {
        merchant_uid: merchant_uid,
      },
    }).then((data) => {
      // 백엔드의 응답이 있어야만 axios의 타임아웃이 안 되고 다음 로직이 실행될 수 있음
      // console.log("data : ", data); // 충전하기 팝업을 켜자마자 data가 출력됨
      // console.log("rsp:", rsp);
      // console.log("결제 성공");
      // window.alert("로우 충전이 완료되었습니다");
      // window.location.reload(); // pc에서는 안 먹히는 것 같아서 일단 주석 처리
      // console.log("data : ", data);
    });
    IMP.init(`${process.env.REACT_APP_PORTONE_IMP_CODE}`); // 고객사 식별코드
    // (백엔드의 대답 여부와 상관없이) 결제창 띄우기
    IMP.request_pay(
      {
        pg: "html5_inicis.MOI1362889", // 로우로우 실연동 PG상점아이디(MID)
        pay_method: "card",
        merchant_uid: merchant_uid, // 주문번호
        name: `${law} 로우 결제`,
        amount: cost, // 결제 가격
        buyer_email: "",
        buyer_name: "",
        buyer_tel: "",
        buyer_addr: "",
        buyer_postcode: "",
        m_redirect_url: `${SERVER_BASE_URL}`,
      },
      function (rsp) {
        // console.log("rsp :", rsp);
        if (rsp.error_code != null) {
          return alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
        }
        if (rsp.success) {
          // console.log("로우 충전이 완료되었습니다");
          // 추후 - 충전팝업 띄우고 main으로 이동 or 새로고침
          //  navigate("/main"); // pc에서 결제 후 main으로 이동(reload 안 먹혀서 대체 방법)
        }
        // const SERVER_URI = config.serverUri;
        // const uri = `/private/payment/complete`;
        // const server_url = `${SERVER_URI}${uri}`;
        // console.log("axios 이전 rsp : ", rsp);

        // if (rsp.success) {
        //   axios({
        //     url: server_url,
        //     method: "post",
        //     headers: { "Content-Type": "application/json" },
        //     withCredentials: true,
        //     data: {
        //       imp_uid: rsp.imp_uid,
        //       merchant_uid: rsp.merchant_uid,
        //       amount: cost,
        //     },
        //   }).then((data) => {
        //     // console.log("rsp:", rsp);
        //     console.log("결제 성공");
        //     window.alert("로우 충전이 완료되었습니다");
        //     // window.location.reload(); // pc에서는 안 먹히는 것 같아서 일단 주석 처리
        //     // console.log("data : ", data);
        //   });
        // } else {
        //   console.log("결제 실패");
        //   window.alert(
        //     "로우 충전에 실패했습니다. 문의하기 서비스를 통해 연락해주십시오"
        //   );
        //   // console.log(rsp);
        // }

        // console.log("axios 후 rsp : ", rsp);
      }
    );
  };

  const priceData = [
    { lowCount: 30, cost: 990 },
    { lowCount: 190, cost: 5900 },
    { lowCount: 400, cost: 11900 },
  ];

  // 특정 lowCount에 대한 cost 확인
  // 예시: lowCount가 190인 경우의 cost 출력
  // console.log("로우 개수가 190일 때 가격:", 5900);
  function getCost(lowCount) {
    law = lowCount; // 사용자가 결제하려는 로우 수 할당
    const data = priceData.find((item) => item.lowCount === lowCount);
    return data ? data.cost : null;
  }

  return (
    <>
      {/* <ModalSuccessLawCharge isOpen={isOpen} onClose={onClose} law={law} /> */}
      <Desktop>
        <ModalBasic
          isOpen={isOpen}
          onClose={onClose}
          width="1000px"
          height="600px">
          <WrapperHeader>
            <Header>{title}</Header>
            <ButtonStyles onClick={onClose}>
              <img
                src={close_button}
                alt="close-button"
                width="32px"
                height="32px"
              />
            </ButtonStyles>
          </WrapperHeader>

          <HrStyles />

          <WrapperContents>
            <LowBoxWrapper>
              <LowBox>
                <HeightInterval height={44} />
                {/* Most Popular 높이 맞추기 위한 height */}
                <TextPriceNonDiscount>
                  <PriceImage src={img30} alt="30" />
                </TextPriceNonDiscount>
                <TextLaw>로우</TextLaw>
                <BoxPrice
                  background="rgba(0, 0, 0, 0.05)"
                  onClick={() => requestPay(getCost(30))}>
                  <BoldText>990₩</BoldText>/vat포함
                </BoxPrice>
              </LowBox>
              <LowBox
                style={{
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 7.5%, rgba(0, 243, 112, 0.06) 49%, rgba(255, 255, 255, 0.00) 91.5%)",
                }}>
                <WrapperLine>
                  <Text
                    color="#00A14A"
                    size="16px"
                    weight="700"
                    height="normal">
                    Most popular!
                  </Text>
                  <HeightInterval height={16} />
                  <LineThroughText>179</LineThroughText>
                  <TextPrice>
                    <PriceImage src={img190} alt="190" />
                  </TextPrice>
                  <TextLaw>로우</TextLaw>
                  <BoxPrice
                    background="rgba(0, 193, 89, 0.1)"
                    onClick={() => requestPay(getCost(190))}>
                    <BoldText>5900₩</BoldText>/vat포함
                  </BoxPrice>
                  <HeightInterval height={16} />
                  <Text
                    color="rgba(0, 0, 0, 0.30)"
                    size="12px"
                    weight="400"
                    height="normal">
                    <Text color="#00A14A">11</Text> 로우 추가 혜택 포함
                  </Text>
                </WrapperLine>
              </LowBox>
              <LowBox>
                <HeightInterval height={24} />
                {/* Most Popular 높이 맞추기 위한 height */}
                <HeightInterval height={16} />
                <LineThroughText>361</LineThroughText>
                <TextPrice>
                  <PriceImage src={img400} alt="400" />
                </TextPrice>
                <TextLaw>로우</TextLaw>
                <BoxPrice
                  background="rgba(0, 69, 203, 0.1)"
                  onClick={() => requestPay(getCost(400))}>
                  <BoldText>11900₩</BoldText>/vat포함
                </BoxPrice>
                <HeightInterval height={16} />
                <Text
                  color="rgba(0, 0, 0, 0.30)"
                  size="12px"
                  weight="400"
                  height="normal">
                  <Text color="#0057FF">39</Text> 로우 추가 혜택 포함
                </Text>
              </LowBox>
            </LowBoxWrapper>
            <HeightInterval height={64} />
            <HeightInterval height={33} />
            <Text
              color="rgba(0, 0, 0, 0.30)"
              size="14px"
              weight="400"
              height="normal">
              *충전한 로우의 유효기간은 구매일로부터 1년입니다.
            </Text>
          </WrapperContents>
        </ModalBasic>
      </Desktop>

      <Mobile>
        <ModalBasic
          isOpen={isOpen}
          onClose={onClose}
          width={window.innerWidth > 599 ? "551px" : "calc(100vw - 100px)"} // Tablet & Mobile diff width
          height="600px">
          <WrapperHeader>
            <Header>{title}</Header>
            <ButtonStyles onClick={onClose}>
              <img
                src={close_button}
                alt="close-button"
                width="24px"
                height="24px"
              />
            </ButtonStyles>
          </WrapperHeader>

          <HrStyles />

          <LowBoxWrapper>
            <LowBox>
              <TextPriceNonDiscount>
                <PriceImage src={img30} alt="30" />
              </TextPriceNonDiscount>
              <BoxPrice
                background="rgba(0, 0, 0, 0.05)"
                onClick={() => requestPay(getCost(30))}>
                <BoldText>990₩</BoldText>
                /vat포함
                <img
                  src={nextButtonImg}
                  alt="ClickButton"
                  width="9px"
                  height="10px"
                />
              </BoxPrice>
            </LowBox>
            <HeightInterval height={24} />
            <LowBox
              style={{
                background:
                  "linear-gradient(180deg, rgba(217, 217, 217, 0.00) 7.5%, rgba(0, 243, 112, 0.06) 49%, rgba(217, 217, 217, 0.00) 91.5%)",
              }}>
              <Text color="#00A14A" size="12px" weight="700" height="normal">
                Most popular!
              </Text>
              <HeightInterval height={8} />
              <Texts>
                <LineThroughText>179</LineThroughText>
                <TextPrice>
                  <PriceImage src={img190} alt="190" />
                </TextPrice>
              </Texts>
              <BoxPrice
                background="rgba(0, 193, 89, 0.1)"
                onClick={() => requestPay(getCost(190))}>
                <BoldText>5900₩</BoldText>
                /vat포함
                <img
                  src={nextButtonImg}
                  alt="ClickButton"
                  width="9px"
                  height="10px"
                />
              </BoxPrice>
              <HeightInterval height={8} />
              <Text
                color="rgba(0, 0, 0, 0.30)"
                size="12px"
                weight="400"
                height="normal">
                <Text color="#00A14A">11</Text> 로우 추가 혜택 포함
              </Text>
            </LowBox>
            <HeightInterval height={24} />
            <LowBox>
              <Texts>
                <LineThroughText>361</LineThroughText>
                <TextPrice>
                  <PriceImage src={img400} alt="400" />
                </TextPrice>
              </Texts>
              <BoxPrice
                background="rgba(0, 69, 203, 0.1)"
                onClick={() => requestPay(getCost(400))}>
                <BoldText>11900₩</BoldText>
                /vat포함
                <img
                  src={nextButtonImg}
                  alt="ClickButton"
                  width="9px"
                  height="10px"
                />
              </BoxPrice>
              <HeightInterval height={8} />
              <Text
                color="rgba(0, 0, 0, 0.30)"
                size="12px"
                weight="400"
                height="normal">
                <Text color="#0057FF">39</Text> 로우 추가 혜택 포함
              </Text>
            </LowBox>
            <HeightInterval height={32} />
            <Text
              color="rgba(0, 0, 0, 0.30)"
              size="10px"
              weight="400"
              height="normal">
              *충전한 로우의 유효기간은 구매일로부터 1년입니다.
            </Text>
          </LowBoxWrapper>
        </ModalBasic>
      </Mobile>
    </>
  );
};

const LineThroughText = styled.span`
  text-decoration: line-through;
  color: #a9b2ae;
  font-family: Rubik;
  font-style: normal;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 1079px) {
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.3);
    padding-right: 15px;
  }
`;

const TextPrice = styled.div`
  display: flex;
  align-items: center;
  height: 104px;

  /* 모바일 환경 */
  @media (max-width: 1079px) {
    height: 71px;
  }
`;

const TextPriceNonDiscount = styled.div`
  display: flex;
  align-items: center;
  height: 104px;

  /* 모바일 환경 */
  @media (max-width: 1079px) {
    justify-content: center;
    height: 71px;
  }
`;

const PriceImage = styled.img`
  height: auto; /* 이미지 높이를 auto로 설정 */
  @media (max-width: 1079px) {
    width: 70%;
  }
`;

const TextLaw = styled.div`
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.6;
`;

const BoxPrice = styled.div`
  color: #000;
  width: 234px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 32px;
  background: ${(props) => props.background}; /* 배경색 props로 전달 */

  &:hover {
    cursor: pointer;
    background: ${(props) =>
      adjustOpacity(props.background, 0.2)}; /* 호버 시 불투명도 0.2로 증가 */
  }

  @media (max-width: 1079px) and (min-width: 600px) {
    width: 473px;
    height: 40px;
    border-radius: 8px;
    font-size: 13px;
    margin-top: 4px;
  }

  @media (max-width: 599px) {
    width: calc(100% - 60px);
    height: 40px;
    border-radius: 8px;
    font-size: 13px;
    margin-top: 4px;
  }
`;

const LowBoxWrapper = styled.div`
  display: flex;
  justify-content: space-around;

  /* 모바일 환경 */
  @media (max-width: 1079px) {
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 12px;
  }
`;

const LowBox = styled.div`
  width: 314px;
  height: auto;
  flex-shrink: 0;
  /* 자식 요소 수직 수평 가운데 정렬 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background}; /* 배경색 props로 전달 */

  @media (max-width: 1079px) and (min-width: 600px) {
    width: 100%;
  }

  @media (max-width: 599px) {
    width: 100%;
    height: auto;
  }
`;

const Header = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline;

  /* 모바일 환경 */
  @media (max-width: 1079px) {
    font-size: 16px;
    margin: 0;
  }
`;

const WrapperHeader = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px 32px 28px;

  @media (max-width: 1079px) {
    margin: 20px 20px 17px;
  }
`;

const HrStyles = styled.hr`
  width: 99.5%; // 100%로 하면 가로세로 스크롤이 생김
  padding: 0;
  margin: 0;
  border: 0.5px solid rgba(0, 0, 0, 0.05);
`;

const WrapperContents = styled.div`
  margin: 55px 32px 24px;

  @media (max-width: 599px) {
    margin: 24px 0px 12px;
  }
`;

const ButtonStyles = styled.div`
  border: none;
  background: transparent;
  display: inline;
  position: absolute;
  right: 24px;

  &:hover {
    cursor: pointer;
  }
`;

const Texts = styled.div`
  width: auto;
  height: 71px;
  display: flex;
  align-items: center;
`;

const BoldText = styled.span`
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 1079px) {
    font-size: 13px;
  }
`;

const WrapperLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 0.5px solid rgba(0, 0, 0, 0.05); /* 왼쪽 선 */
  border-right: 0.5px solid rgba(0, 0, 0, 0.05); /* 오른쪽 선 */
`;

export default ModalPayment;
