import React from "react";
import styled from "styled-components";
import HeightInterval from "../Components/HeightInterval.jsx";

const Info = ({}) => {
  return (
    <Wrapper>
      <UpperText>
        어떤 문제 상황인지
        <br />
        구체적으로 들려주세요
      </UpperText>
      <HeightInterval height={8} />
      <LowerText>들려준 상황과 관련된 유사 판례들을 볼 수 있습니다</LowerText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media (max-width: 10000px) and (min-width: 1141px) {
    width: 680px;
  }

  @media (max-width: 1140px) and (min-width: 600px) {
    width: 531px;
  }

  @media (max-width: 599px) {
    width: calc(100% - 48px);
  }
`;

const UpperText = styled.div`
  color: #000;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;

  @media (max-width: 10000px) and (min-width: 600px) {
    font-size: 48px;
    line-height: 64px;
  }

  @media (max-width: 599px) {
    font-size: 32px;
    line-height: 42px;
  }
`;

const LowerText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-style: normal;

  @media (max-width: 10000px) and (min-width: 600px) {
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
  }

  @media (max-width: 599px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
  }
`;

export default Info;
