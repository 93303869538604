import React from "react";
import styled from "styled-components";
import ModalBasic from "./ModalBasic.jsx";

// ModalBasic 컴포넌트를 세분화한 문의하기 제출 확인 묻는 모달창 컴포넌트
const ModalSubmitCheck = ({ isOpen, onClose, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm(); // 추가적인 처리 (CaseComparison 보이기)
  };

  return (
    <ModalBasic
      isOpen={isOpen}
      onClose={onClose}
      width={window.innerWidth > 430 ? "352px" : "270px"}
      height={window.innerWidth > 430 ? "98px" : "82px"}
      padding={window.innerWidth > 430 ? "24px" : "21px"}>
      {/* Modal 창에 기본적으로 위아래 margin 24px이 있어서 height를 146px 대신 96px로 설정 */}
      <Header>이대로 제출하시겠습니까?</Header>
      <ButtonWrapper>
        <ButtonStyles type="cancel" onClick={onClose}>
          이전
        </ButtonStyles>
        <ButtonStyles
          type="confirm"
          onClick={handleConfirm}
          style={{ color: "#00F370" }}>
          제출
        </ButtonStyles>
      </ButtonWrapper>
    </ModalBasic>
  );
};

const Header = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-top: 8px;
  margin-left: 8px;

  @media (max-width: 430px) {
    font-size: 16px;
    margin-top: 5px;
    margin-left: 3px;
    line-height: normal;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 8px;
  margin-top: 18px;

  @media (max-width: 430px) {
    margin-right: 3px;
    margin-top: 16px;
  }
`;

const ButtonStyles = styled.div`
  padding: 8px 16px;
  margin-left: 8px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 6px;
  background: ${(props) => (props.type === "cancel" ? "#F2F2F2" : "#000")};
  color: ${(props) => (props.type === "cancel" ? "#000" : "#FFF")};

  @media (max-width: 430px) {
    margin-left: 8px;
    font-size: 14px;
    width: 38px;
    text-align: center;
  }
`;

export default ModalSubmitCheck;
