import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo.jsx";
import no_profile from "../img/profile.png";
import inquiry from "../img/inquiry.png";
import plus from "../img/plus.png";
import close_button from "../img/close_button.png";
import inquiryLogBar from "../img/inquiry.png";
import terms from "../img/terms.png";
import ModalMyPage from "../Components/Modal/ModalMyPage.jsx";
import LogList from "../Components/LogList.jsx";
import ModalInquiry from "./Modal/ModalInquiry.jsx";
import ModalTermsOfService from "../Components/Modal/ModalTermsOfService.jsx";
import config from "../config.js";
import { gradientBorder } from "../styles/mixin.js";

const BannerMobile = () => {
  /* 이용약관 모달창 */
  const [isTermsModalOpen, setTermsModalIsOpen] = useState(false);
  const handleOpenTermsModal = () => {
    setTermsModalIsOpen(true);
  };
  const handleCloseTermsModal = () => {
    setTermsModalIsOpen(false);
  };

  /* 문의하기 모달창 */
  const [isInquiryModalOpen, setInquiryModalIsOpen] = useState(false);
  const handleOpenInquiryModal = () => {
    setInquiryModalIsOpen(true);
  };
  const handleCloseInquiryModal = () => {
    setInquiryModalIsOpen(false);
  };

  /* 마이페이지 모달창 */
  const [isMyPageModalOpen, setMyPageModalIsOpen] = useState(false);
  const imgRef = useRef(null);

  const handleOpenMyPageModal = () => {
    setMyPageModalIsOpen(true);
  };
  const handleCloseMyPageModal = () => {
    setMyPageModalIsOpen(false);
  };

  /* 로그바 */
  const [isLogbarOpen, setLogbarOpen] = useState(false); // 로그바 상태를 관리하는 상태 변수

  // 로그바를 여는 함수
  const toggleLogbar = () => {
    setLogbarOpen(true);
  };

  // 회색 배경을 클릭하여 로그바를 닫는 함수
  const closeLogbar = () => {
    setLogbarOpen(false);
  };

  /* 전체 Log 가져오기 */
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    // REACT_APP_SERVER_DOMAIN_DEV
    // REACT_APP_SERVER_DOMAIN_DEPLOY

    // const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN_DEPLOY; // 서버 API 도메인
    const SERVER_URI = config.serverUri;
    const URI = "/private/logs";

    fetch(`${SERVER_URI}${URI}`, {
      credentials: "include", // 인증 정보 포함
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setLogs(data);
      })
      .catch((error) => console.error("Error fetching logs:", error));

    /* localhost 테스트용 질문 내역*/
    // const data = JSON.parse(
    //   '[{"id": 1, "content": "카페에서 음료를 사람에게 쏟았지뭐야? 그 사람에 얼룩진 옷을 세탁하려면 비용이 드는데, 세탁비는 내가 내줘야해?"}]'
    // );
    // setLogs(data);
  }, []);

  const navigate = useNavigate();
  const moveToMain = () => {
    navigate("/main");
  };

  return (
    <Wrapper>
      <StyledLogButtonImage
        src={inquiryLogBar}
        alt="모바일 질문 내역"
        onClick={toggleLogbar}
      />

      {/* 왼쪽 로그바 */}
      <LogbarMobile isOpen={isLogbarOpen}>
        {/* 로그바 내용 */}
        <LogbarTitle>
          <CustomButton
            image_src={plus}
            alt_text="새로운 상황"
            button_text="새로운 상황"
            width="70%"
            height="50px"
            imgWidth={36}
            imgHeight={36}
            gap={10}
            onClick={() => moveToMain()}>
            <ButtonImg
              src={plus}
              alt="새로운 상황"
              imgWidth={36}
              imgHeight={36}
            />
            <ButtonText>새로운 상황</ButtonText>
          </CustomButton>
          <ButtonStyles onClick={closeLogbar}>
            <img
              src={close_button}
              alt="close-button"
              width="24px"
              height="24px"
            />
          </ButtonStyles>
        </LogbarTitle>
        <LogList logs={logs}></LogList>
        <InquiryTermsBox>
          <ModalInquiry
            isOpen={isInquiryModalOpen}
            onCloseInquiry={handleCloseInquiryModal}
          />
          <CustomButton
            image_src={inquiry}
            alt_text="문의하기"
            button_text="문의하기"
            fontSize="16px"
            fontWeight="600"
            width="100%"
            height="44px"
            lineHeight="26px"
            onClick={() => handleOpenInquiryModal(true)}
            imgWidth={32}
            imgHeight={32}
            gap={8}>
            <ButtonImg
              src={inquiry}
              alt="문의하기"
              imgWidth={32}
              imgHeight={32}
            />
            <ButtonText>문의하기</ButtonText>
          </CustomButton>
          <ModalTermsOfService
            isOpen={isTermsModalOpen}
            onClose={handleCloseTermsModal}
          />
          <CustomButton
            image_src={terms}
            alt_text="이용약관"
            button_text="이용약관"
            fontSize="16px"
            fontWeight="600"
            width="100%"
            height="44px"
            lineHeight="26px"
            onClick={() => handleOpenTermsModal(true)}
            imgWidth={32}
            imgHeight={32}
            gap={8}>
            <ButtonImg
              src={terms}
              alt="이용약관"
              imgWidth={32}
              imgHeight={32}
            />
            <ButtonText>이용약관</ButtonText>
          </CustomButton>
        </InquiryTermsBox>
      </LogbarMobile>
      {/* 사이드바가 열렸을 때, 회색 배경 */}
      {isLogbarOpen && <LogbarOverlay onClick={closeLogbar} />}

      <Logo />

      <ModalMyPage
        isOpen={isMyPageModalOpen}
        onClose={handleCloseMyPageModal}
        width="275px"
        height="auto"
        title="마이페이지"
        triggerRef={imgRef}
      />
      <StyledImageWrapper
        ref={imgRef}
        onClick={() => imgRef.current && handleOpenMyPageModal()} // 이미지 ref가 유효한 경우에만 모달 열기
      >
        <StyledImage src={no_profile} alt="마이페이지 이미지" />
      </StyledImageWrapper>
    </Wrapper>
  );
};

const CustomButton = styled.button`
  padding-top: 8px;
  padding-left: 10px;
  padding-bottom: 10px;
  background-color: transparent;
  width: ${(props) => (props.width === "auto" ? "auto" : `${props.width}`)};
  height: ${(props) => (props.height === "auto" ? "auto" : `${props.height}`)};
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: ${(props) => (props.gap !== undefined ? `${props.gap}px` : "0px")};
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const ButtonImg = styled.img`
  width: ${(props) =>
    props.imgWidth === "auto" ? "auto" : `${props.imgWidth}px`};
  height: ${(props) =>
    props.imgHeight === "auto" ? "auto" : `${props.imgHeight}px`};
  flex-shrink: 0;
`;

const ButtonText = styled.span`
  font-family: Pretendard;
  font-style: normal;
  color: ${(props) => props.fontColor || "rgba(0, 0, 0, 0.6)"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "600"};
  line-height: ${(props) => props.lineHeight || "26px"};
  padding-right: ${(props) => props.paddingRight || "0px"};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

// 로그바 열리는 버튼
const StyledLogButtonImage = styled.img`
  width: 40px;
  height: 40px;
`;

// Mobile, 왼쪽 로그바 컴포넌트
const LogbarMobile = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) =>
    props.isOpen ? "0" : "-320px"}; /* isOpen 상태에 따라 왼쪽으로 이동 */
  width: 312px;
  height: 100%;
  background-color: #fff;
  transition: left 0.3s ease; /* 왼쪽 이동 효과 추가 */
  z-index: 101; /* 회색 배경 위에 표시 */
  display: flex;
  flex-direction: column;
`;

const LogbarTitle = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  height: 96px; /* 기종에 따라 height가 늘어나거나 줄어드는 경우가 있어 35%(약 307px)으로 맞춤 */
  margin-left: 24px;
`;

const ButtonStyles = styled.div`
  border: none;
  background: transparent;
  display: inline;
  position: absolute;
  right: 24px;
`;

// 사이드바가 열렸을 때, 회색 배경 컴포넌트
const LogbarOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 투명도를 조절하여 회색 배경을 생성 */
  z-index: 100; /* 다른 요소 위에 표시 */
`;

const StyledImageWrapper = styled.div`
  ${gradientBorder}

  position: relative;
  width: 40px; // 테두리 두께를 포함한 크기
  height: 40px;
  border-radius: 50%; // 원형 테두리를 유지하기 위해 50%로 설정
  overflow: hidden;
  display: inline-block;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  box-sizing: border-box; // 테두리를 포함한 크기 계산
`;

const InquiryTermsBox = styled.div`
  margin-top: auto; /* 바닥에 붙게 하기 */
  padding: 10px; /* 세세한 조정 다시 잡기 */
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 84px;
  display: flex;
  flex-direction: column;
  gap: 8px; /* 위아래 8px 간격 */

  @media (max-width: 599px) {
    // display: none; // 모바일 사이드바에도 문의하기/이용약관 버튼 추가
    margin-bottom: 32px;
  }
`;

export default BannerMobile;
