import React from "react";
import styled from "styled-components";
import ModalBasic from "../Modal/ModalBasic.jsx";

// 로우 충전 알림 모달창
const ModalSuccessLawCharge = ({ isOpen, onClose, law }) => {
  return (
    <ModalBasic
      isOpen={isOpen}
      onClose={onClose}
      width={window.innerWidth > 430 ? "400px" : "312px"}
      height={window.innerWidth > 430 ? "188px" : "180px"}>
      <ContentWrapper>
        <TextWrapper>
          <Header>{law}로우가 충전되었습니다!</Header>
          <Content>
            자세한 충전 내역은 오른쪽 상단 프로필을 통해 확인 가능합니다.
          </Content>
        </TextWrapper>
        <ButtonWrapper>
          <ButtonStyles
            type="confirm"
            onClick={onClose}
            style={{ color: "#00F370" }}>
            확인
          </ButtonStyles>
        </ButtonWrapper>
      </ContentWrapper>
    </ModalBasic>
  );
};

const ContentWrapper = styled.div`
  margin: 24px;

  @media (max-width: 430px) {
    margin: 21px;
  }
`;

const TextWrapper = styled.div`
  width: 313px;
  margin-top: 8px;
  margin-left: 8px;

  @media (max-width: 430px) {
    width: 261px;
    margin-top: 5px;
    margin-left: 3px;
  }
`;

const Header = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline;

  @media (max-width: 430px) {
    font-size: 16px;
    margin-top: 0px;
    margin-left: 0px;
  }
`;

const Content = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 12px;

  @media (max-width: 430px) {
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
  }

  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 8px;

  @media (max-width: 430px) {
    margin-top: 16px;
    margin-right: 3px;
  }
`;

const ButtonStyles = styled.button`
  width: 70px;
  height: 40px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 6px;
  background: ${(props) => (props.type === "cancel" ? "#F2F2F2" : "#000")};
  color: ${(props) => (props.type === "cancel" ? "#000" : "#FFF")};

  &:disabled {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.3);
    cursor: not-allowed;
  }
`;

export default ModalSuccessLawCharge;
