import React from "react";
import styled from "styled-components";
import termsOfServiceDataRaw from "../../data/termsOfService.json";
import ModalBasic from "./ModalBasic.jsx";
import close_button from "../../img/close_button.png";

// JSON파일의 줄바꿈 문자(\\n)를 <br>로 변환하여 새 객체 생성
// dangerouslySetInnerHTML를 이용하여 렌더링
const termsOfServiceData = JSON.parse(
  JSON.stringify(termsOfServiceDataRaw),
  (key, value) =>
    typeof value === "string" ? value.replace(/\\n/g, "<br>") : value
);

// ModalBasic 컴포넌트를 세분화한 이용약관 컴포넌트
const ModalTermsOfService = ({ isOpen, onClose }) => {
  return (
    <ModalBasic
      isOpen={isOpen}
      onClose={onClose}
      width={window.innerWidth > 599 ? "550px" : "312px"}
      height={window.innerWidth > 599 ? "770px" : "600px"}>
      {/* Hr이 모달창 양끝에 닿게하기 위해 WrapperHeader HrStyles WrapperContents 구조로 변경 */}
      {/* <WrapperContents> */}

      <WrapperHeader>
        <Header>{termsOfServiceData.title}</Header>
        <ButtonStyles onClick={onClose}>
          <img src={close_button} alt="close-button" />
        </ButtonStyles>
      </WrapperHeader>

      <HrStyles />

      <WrapperContents>
        <ScrollableContent>
          {termsOfServiceData.rules.map((rule) => (
            <div key={rule.rule_id}>
              <Title>{rule.rule_title}</Title>
              {rule.subtitles.map((subtitle) => (
                <div key={subtitle.title}>
                  <SubTitle>{subtitle.title}</SubTitle>
                  <Content
                    dangerouslySetInnerHTML={{ __html: subtitle.content }}
                  />
                </div>
              ))}
            </div>
          ))}
          <Title>[부칙]</Title>
          <Content>
            본 약관은 2024년 2월 1일부터 13일간 공지하고, 2024년 2월 15일부터
            시행한다.
          </Content>
          <Title>변경 이력</Title>
          <Content>2024년 2월 1일 개정</Content>
        </ScrollableContent>
      </WrapperContents>
    </ModalBasic>
  );
};

const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  margin: 24px 24px 25px;

  @media (max-width: 599px) {
    margin: 20px 20px 17px;
  }
`;

const Header = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* 텍스트 중앙에 배치하기 */
  width: 100%;
  text-align: center;
  display: inline-block;

  @media (max-width: 599px) {
    font-size: 16px;
  }
`;

// 총칙
const Title = styled.div`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;

  @media (max-width: 599px) {
    font-size: 12px;
  }
`;

// 조항
const SubTitle = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 16px;
  margin-bottom: 8px;

  @media (max-width: 599px) {
    font-size: 12px;
  }
`;

const Content = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 599px) {
    margin: 0;
    font-size: 12px;
    line-height: 20px;
  }
`;

const HrStyles = styled.hr`
  width: 99.5%; // 100%로 하면 가로세로 스크롤이 생김
  padding: 0;
  margin: 0;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
`;

const ButtonStyles = styled.div`
  border: none;
  background: transparent;
  display: flex;
  position: absolute;
  right: 0px;
  width: 32px;
  height: 32px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 599px) {
    width: 24px;
    height: 24px;
  }
`;

const WrapperContents = styled.div`
  margin: 12px 11px 38px 32px;

  @media (max-width: 599px) {
    margin: 20px 7px 29px 20px;
  }
`;

const ScrollableContent = styled.div`
  // Contents 차지 height는 총 652px
  //width: 103%; /* 스크롤바의 오른쪽 간격 10px 맞추기 위함 */
  height: 644px; // padding-top 32px을 고려한 간격
  padding-right: 12px;
  overflow-y: auto;

  /* 스크롤바 스타일링 */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  @media (max-width: 599px) {
    // Contents 차지 height는 총 510px
    //width: 103%;
    height: 490px;
    padding-right: 7px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 81px;
    }
  }
`;

export default ModalTermsOfService;
