import React from "react";
import styled from "styled-components";
import stop from "../img/stop.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
`;

const Text = styled.div`
  font-size: 18px;
  margin-top: 10px;
`;

const Highlight = styled.span`
  color: orange;
`;

const Bold = styled.span`
  font-style: normal;
  font-weight: 800;
`;

const Stop = () => {
  return (
    <Container>
      <Image src={stop} alt="Stop sign" />
      <Text>
        <h1>
          현재 <Highlight>서버 점검중</Highlight>입니다.
        </h1>
        The requested page is <Highlight>under maintenance</Highlight> <br />
        <br />
        <br />
        치명적인 문제가 발생됨이 확인되었습니다. 사용자경험 향상을 위해 다음과
        같이 점검을 하고자 합니다 <br />
        <br />
        <Bold>점검시간</Bold> : 2024년 8월 26일(월) 12시 30분 ~ 2024년 8월
        27일(화) 00시 00분 <br />
        <br />
        <br />
      </Text>
    </Container>
  );
};

export default Stop;
