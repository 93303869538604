import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Desktop,
  LargeTablet,
  SmallTablet,
  Mobile,
} from "../MediaQuery/QueryPageMediaQuery.js";
import styled from "styled-components";
import Banner from "../Components/Banner.jsx";
import LogBar from "../Components/LogBar.jsx";
import Result from "../Components/Result.jsx";
import BannerMobile from "../Components/BannerMobile.jsx";
import HeightInterval from "../Components/HeightInterval.jsx";
import ToggleDropdown from "../Components/ToggleDropdown.jsx";
import Loading from "../Components/Loading.jsx";
import ModalUnauthorizedUser from "../Components/Modal/ModalUnauthorizedUser.jsx";
import useStore from "../store.js";
import { useNavigate } from "react-router-dom";
import ModaRefreshlDetected from "../Components/Modal/ModaRefreshlDetected.jsx";
import Stop from "../Components/Stop";
import { Helmet } from "react-helmet-async";

// 로딩 상태일 때 숨기기 위한 Wrapper 컴포넌트
const LoadingWrapper = ({ isLoading, children }) => {
  return isLoading ? null : <>{children}</>;
};

// 질문결과 페이지
// 특정 로그 조회
const Query = () => {
  const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false); // Unauthorized 모달창 상태 변수

  const { id } = useParams(); // URL의 log_id 변화시 Logbar와 Result 내용 업데이트

  const navigate = useNavigate();
  const initialRender = useRef(true);
  const {
    loading,
    updateUserInfo,
    updateUserLaw,
    deleteCookieByKey,
    isRefreshDetected,
    setRefreshDetected,
    initializeStore,
  } = useStore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await updateUserInfo(setShowUnauthorizedModal); // 비동기 함수 호출
        await updateUserLaw();
      } catch (error) {
        console.error("Failed to update user info or law history:", error);
      }
    };

    fetchData(); // 비동기 함수 호출
  }, []);

  useEffect(() => {
    // console.log(" Query페이지에서 isRefreshDetected : ", isRefreshDetected);
    if (initialRender.current) {
      initialRender.current = false; // 첫 렌더링 후에 false로 설정
      return;
    }

    if (!showUnauthorizedModal) {
      // Unauthorized 모달이 닫힐 때 로그인 페이지로 리디렉션 수행
      navigate("/");
    }
  }, [showUnauthorizedModal]);

  // 로컬 스토리지와 Zustand store의 상태를 동기화
  useEffect(() => {
    initializeStore();
  }, []);

  const closeModal = () => {
    setRefreshDetected(false);
  };

  return (
    // <Stop />
    <>
      <Helmet>
        <title>나의 상황과 판례 비교하기 | 로우로우</title>
        <meta
          name="description"
          content="나의 상황과 딱맞는 판례를 AI가 엄선했어요. 문제해결에 필요한 판례로 나 자신을 보호하세요."
        />
        <meta
          name="keywords"
          content="변호사, 변호사 추천, 선임비용, 무료법률상담, 전화법률상담, 법률상담, 법, 법률, 사기, 이혼, 범죄, 채무"
        />
      </Helmet>
      <ModaRefreshlDetected isOpen={isRefreshDetected} onClose={closeModal} />
      <Desktop>
        {loading && <Loading />}
        <LoadingWrapper isLoading={loading}>
          <Wrapper>
            <LogBar id={id} />
            <MainWrapper>
              <Banner />
              <Result id={id} />
            </MainWrapper>
          </Wrapper>
        </LoadingWrapper>
      </Desktop>

      <LargeTablet>
        {loading && <Loading />}
        <LoadingWrapper isLoading={loading}>
          <>
            <HeightInterval height={21} />
            <WrapperMobile>
              <BannerMobile />
            </WrapperMobile>
            <HeightInterval height={22} />
            <Result id={id} />
          </>
        </LoadingWrapper>
      </LargeTablet>

      <SmallTablet>
        {loading && <Loading />}
        <LoadingWrapper isLoading={loading}>
          <>
            <HeightInterval height={21} />
            <WrapperMobile>
              <BannerMobile />
            </WrapperMobile>
            <HeightInterval height={22} />
            <Result id={id} />
          </>
        </LoadingWrapper>
      </SmallTablet>

      <Mobile>
        {loading && <Loading />}
        <LoadingWrapper isLoading={loading}>
          <>
            <FixedWrapper>
              <HeightInterval height={20} />
              <WrapperMobile>
                <BannerMobile />
              </WrapperMobile>
              <HeightInterval height={24} />
              <ToggleDropdown id={id} />
            </FixedWrapper>
            <HeightInterval height={40} />
            <WrapperMobile>
              <Result id={id} />
            </WrapperMobile>
          </>
        </LoadingWrapper>
      </Mobile>
      {showUnauthorizedModal && (
        <ModalUnauthorizedUser
          isOpen={showUnauthorizedModal}
          onClose={() => setShowUnauthorizedModal(false)}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex; /* 가로정렬 */
`;

const WrapperMobile = styled.div`
  width: auto;

  /* 배너 좌우 margin */
  @media (max-width: 1140px) {
    margin: 0px 24px;
  }

  @media (max-width: 744px) {
    margin: 0px 32px;
  }

  @media (max-width: 480px) {
    margin: 0px 24px;
  }
`;

const MainWrapper = styled.div`
  width: calc(100% - 312px); // LogBar의 너비 312px 이외 영역
  margin-left: 312px; /* LogBar의 영역 logBarMinWidth 20% */
`;

const FixedWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100; /* 다른 콘텐츠 위에 표시되도록 z-index 설정 */
  background-color: white; /* 배경 색상 설정 (필요 시) */
`;

export default Query;
