import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import useStore from "../store.js";
import button from "../img/ask_button.png";
import { Desktop, Mobile } from "../MediaQuery/OnboardingPageMediaQuery.js";
import ModalPayCheck from "./Modal/ModalPayCheck";
import ModalAskError from "./Modal/ModalAskError.jsx";
// import ModalUnsavedWarning from "./Modal/ModalUnsavedWarning.jsx"; // 필요 없어진 컴포넌트
import { gradientBorder } from "../styles/mixin.js";

/* 중복되는 css 코드 */
const textStyle = css`
  font-family: Pretendard;
  font-size: 8pt;
  font-style: normal;
  line-height: 26px;
`;

const AskForm = ({ initialTextValue }) => {
  let [inputCount, setInputCount] = useState(0);
  let [textValue, setTextValue] = useState(initialTextValue || ""); // 초기 텍스트 값 설정
  const [showModal, setShowModal] = useState(false); // 질문 검색 로우 차감 확인용 Modal창
  const [showAskErrorModal, setShowAskErrorModal] = useState(false); // 질문하기 에러 알림 Modal창
  let [isButtonDisabled, setIsButtonDisabled] = useState(true); // 입력 필드 글자 입력에 따른 버튼의 활성화 여부를 관리

  const {
    fetchPostRequest,
    setLoading,
    setTextValue: setStoreTextValue,
  } = useStore();

  useEffect(() => {
    setTextValue(initialTextValue);
  }, [initialTextValue]); // 오류시 사용자가 입력한 상황 저장

  const onInputHandler = (e) => {
    const text = e.target.value;
    setTextValue(text); // 입력된 텍스트 업데이트
    setInputCount(text.length);
    setIsButtonDisabled(text.length === 0); // 입력된 텍스트가 없으면 버튼 비활성화
  };

  // 질문시 10로우 차감 확인용 모달창
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // 질문 버튼 눌렀을 때 실행되는 함수
  const handleQueryAction = () => {
    handleOpenModal();
  };

  const handleConfirmModal = () => {
    // 사용자가 충분한 로우 개수를 가진 상태에서 결제 동의를 했을 경우 실행
    setShowModal(false); // 모달 닫기
    setLoading(true);
    postTextToServer();
  };

  // 질문하기 요청중 오류 알림 모달창 띄우기
  const handleShowAskErrorModal = () => {
    setShowAskErrorModal(true);
  };

  // 질문하기 요청중 오류 알림 모달창 끄기
  const handleCloseAskErrorModal = () => {
    // 오류 알림을 사용자가 닫은 후에 메인페이지로 돌아갈 수 있도록 설정
    setShowAskErrorModal(false); // 모달 닫기
    setLoading(false);
    setTimeout(() => {
      navigate(`/main`, { state: { textValue } });
    }, 500); // 0.5초 후에 메인 페이지로 이동하며 textValue(사용자가 적었던 상황) 전달
  };

  // 사용자가 입력한 text를 body에 담아 서버에 전송
  const navigate = useNavigate();
  const postTextToServer = async () => {
    try {
      setStoreTextValue(textValue); // postTextToServer 실행하자마자 store에 textValue 저장

      const response = await fetchPostRequest("/private/query", {
        content: textValue,
      });
      // const responseData = await response.json(); // Error 암 발생 원인 // response이 이미 json
      if (response.error) {
        // !response.ok 안 돼서 대책
        throw new Error("질문 응답 객체 에러");
      }

      if (!response.inquiries) {
        // responseData에 inquiries가 없으면 에러
        throw new Error("응답 데이터에 inquiries가 없습니다.");
      }

      // 추후 에러 핸들링 추가 - 1분 이상 응답 없으면 에러 throw

      /*  성공시 서버로부터 response 오면 QueryPage로 이동 */
      setLoading(false); // false 설정해줘야 메인페이지로 갔을 때 <Loading> 아닌 <Ask> 컴포넌트로 잘 보이게 됨
      navigate(`/main/q/${response.log_id}`); // 성공시 QueryPage로 이동
    } catch (error) {
      /* 실패시 - 질문하기 요청 오류 알림 모달창 띄우기*/
      handleShowAskErrorModal(); // 오류 알림을 사용자가 닫은 후에 메인페이지로 돌아갈 수 있도록 설정
    }
  };

  // 작업 중 나가려할 때 기본 브라우저 기능 실행
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (textValue.trim() !== "") {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [textValue]);

  return (
    <>
      <Desktop>
        <Wrapper>
          <InputField
            type="text"
            placeholder="문제 상황을 자세하게 입력해주세요."
            onChange={onInputHandler}
            maxLength="1000"
            value={textValue}
          />
          <SideField>
            <StyledParagraph
              countcolor={inputCount >= 0 ? "#00F370" : "inherit"}>
              <StyledSpan countcolor={inputCount >= 0 ? "#00F370" : "inherit"}>
                {inputCount}
              </StyledSpan>
              <SecondSpan> / 1000 </SecondSpan>
            </StyledParagraph>
            {/* 버튼 클릭 시 onQueryAction 호출도 호출 */}
            <Button
              type="button"
              onClick={handleQueryAction}
              disabled={isButtonDisabled}
              alt="질문하기/검색하기"></Button>
          </SideField>
        </Wrapper>
      </Desktop>

      <Mobile>
        <WrapperMobile>
          <InputFieldMobile
            type="text"
            placeholder="문제 상황을 자세하게 입력해주세요."
            onChange={onInputHandler}
            maxLength="1000"
            value={textValue}
          />
          <SideFieldMobile>
            <StyledParagraph
              countcolor={inputCount >= 0 ? "#00F370" : "inherit"}>
              <StyledSpan countcolor={inputCount >= 0 ? "#00F370" : "inherit"}>
                {inputCount}
              </StyledSpan>
              <SecondSpan>/ 1000 </SecondSpan>
            </StyledParagraph>
            {/* 버튼 클릭 시 onQueryAction 호출도 호출 */}
            <Button
              type="button"
              onClick={handleQueryAction}
              disabled={isButtonDisabled}
              alt="질문하기/검색하기"></Button>
          </SideFieldMobile>
        </WrapperMobile>
      </Mobile>

      <ModalAskError
        isOpen={showAskErrorModal}
        onClose={handleCloseAskErrorModal}
      />
      <ModalPayCheck
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleConfirmModal}
        law={10}
        buttonType="queryButton"
        headerContent="검색하기"
        additionalContent={
          <span
            dangerouslySetInnerHTML={{
              __html:
                "사용자의 상황과 유사한 상위 5개의 판례를 검색 결과로<br />제공하며, 검색 시마다 다른 결과를 얻을 수 있습니다.",
            }}
          />
        }
      />
    </>
  );
};

const Wrapper = styled.div`
  ${gradientBorder}

  width: 680px;
  height: 332px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
`;

const WrapperMobile = styled.div`
  ${gradientBorder}

  width: 624px;
  height: 255px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;

  @media (max-width: 10000px) and (min-width: 1141px) {
    width: 680px;
    height: 332px;
    flex-direction: row;
  }

  @media (max-width: 1140px) and (min-width: 600px) {
    width: 531px;
    height: 396px;
    flex-direction: row;
  }

  @media (max-width: 599px) {
    width: calc(100% - 48px);
    height: 325px;
  }
`;

const InputField = styled.textarea`
  flex-shrink: 0;
  width: 522px;
  border: none;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #000; /* 기본 텍스트 색상을 검은색으로 설정 */
  /* 텍스트를 맨 위로 배치하는 스타일 */
  vertical-align: top; /* 텍스트를 요소의 맨 위로 배치 */
  margin: 24px 38px 24px 32px;
  padding: 0; /* textarea에 기본적으로 들어가있는 설정 제거 */
  resize: none;
  overflow-y: auto;

  /* 선택될 때 나타나는 테두리 제거 */
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.3); /* 플레이스홀더 텍스트 색상 설정 */
  }

  /* 스크롤바 스타일링 */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
`;

const InputFieldMobile = styled.textarea`
  flex-shrink: 0;
  border: none;
  height: 156px;
  font-family: Pretendard;
  line-height: 23px;
  font-size: 14px;
  padding: 0; /* textarea 기본 설정된 padding 값 제거 */

  @media (max-width: 10000px) and (min-width: 1141px) {
    width: 522px;
    height: auto;
    margin: 24px 38px 24px 32px;
  }

  @media (max-width: 1140px) and (min-width: 600px) {
    width: 373px;
    height: auto;
    margin: 24px 38px 24px 32px;
  }

  @media (max-width: 599px) {
    height: 237px;
    margin: 16px 16px 0px 16px;
  }

  /* 텍스트를 맨 위로 배치하는 스타일 */
  vertical-align: top; /* 텍스트를 요소의 맨 위로 배치 */
  margin: 16px 10px 0px 16px; /* 스크롤 오른쪽 10px 떨어지기 */
  resize: none; /* 크기 조절 비활성화 */
  overflow-y: auto;

  /* 선택될 때 나타나는 테두리 제거 */
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3); /* 플레이스홀더 텍스트 색상 설정 */
  }

  /* 스크롤바 스타일링 */
  &::-webkit-scrollbar {
    width: 10px; /* 스크롤바의 전체 너비를 설정 */
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05); /* 스크롤바 막대 색상 */
    border-radius: 4px;
    border: 2px solid rgba(255, 255, 255, 1); /* 스크롤바와 내용 사이에 여백을 주기 위한 테두리 */
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 1); /* 스크롤바 트랙 색상 */
    margin: 8px 0; /* 스크롤 위로부터 24px 맞추기 위해 margin-top 16px과 합작 */
  }
`;

const SideField = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center; /* 자식 요소 수직 가운데 정렬 */
  margin-right: 24px;
`;

const SideFieldMobile = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-left: 8%;

  Button {
    width: 48px;
    height: 48px;
    margin-left: 50%;

    @media (max-width: 10000px) and (min-width: 1141px) {
      width: 64px;
      height: 64px;
      margin-left: 0;
    }

    @media (max-width: 1140px) and (min-width: 600px) {
      margin-left: 0;
    }

    @media (max-width: 599px) {
      margin-right: 16px;
      margin-left: auto;
    }
  }
  StyledParagraph {
    align-items: flex-end;
  }

  @media (max-width: 10000px) and (min-width: 1141px) {
    flex-direction: column;
    margin-top: 0;
    margin-left: 0;
  }

  @media (max-width: 1140px) and (min-width: 600px) {
    flex-direction: column;
    align-items: center;
    width: 64px;
    margin-top: 0;
    margin-left: 0;
  }

  @media (max-width: 599px) {
    margin-left: 16px;
    margin-top: 0;
  }
`;

const StyledParagraph = styled.p`
  color: ${(props) => props.countcolor};
  text-align: center;
  width: 64px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 24px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  margin-top: 24px;
`;

const StyledSpan = styled.span`
  ${textStyle}
  color: ${(props) => props.countcolor};
  font-weight: 700;
`;

const SecondSpan = styled.span`
  ${textStyle}
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
  margin-left: 2px;
`;

const Button = styled.button`
  margin-top: auto; /* 상단 여백 추가 */
  margin-bottom: 24px;
  background: url(${button}) no-repeat center center;
  background-size: cover;
  width: 64px; /* 이미지의 너비에 맞춤 */
  height: 64px; /* 이미지의 높이에 맞춤 */
  border: none; /* 테두리 제거 */
  cursor: pointer; /* 마우스 커서를 포인터로 변경 */

  /* 버튼이 비활성화 상태일 때의 스타일 */
  &:disabled {
    pointer-events: none;
    background-blend-mode: normal; /* blend 모드 해제 */
    opacity: 0.5; /* 투명도를 낮춰서 비활성화 상태를 나타냄 */
  }

  /* 활성화와 호버가 둘 다 충족됐을 때 */
  &:hover:enabled {
    background-color: rgba(255, 255, 255, 0.3); /* 마우스 hover시 배경색 추가 */
    background-blend-mode: screen; /* 배경 이미지와 색상 혼합 모드 설정 */
  }

  @media (max-width: 599px) {
    margin-bottom: 16px;
  }
`;

export default AskForm;
