import React from "react";
import ModalBasic from "./ModalBasic.jsx";
import styled from "styled-components";
import config from "../../config.js";
import { useNavigate } from "react-router-dom";

// ModalBasic 컴포넌트를 세분화한 삭제 재확인 컴포넌트
const ModalDeleteCheck = ({ isOpen, onClose, logContent, logId }) => {
  const navigate = useNavigate();

  const deleteLog = async () => {
    const SERVER_URI = config.serverUri;
    const uri = `/private/logs/${logId}`;
    const url = `${SERVER_URI}${uri}`;

    if (!logId) {
      console.error("logId가 정의되지 않았습니다");
      return;
    }

    try {
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // 추후 LAWLOW-267 - 임시로 alert
      window.alert("질문 내역이 삭제되었습니다");
      navigate("/main"); // 삭제 성공시 메인 페이지로 이동
    } catch (error) {
      console.error("로그 삭제 중 오류 발생:", error);
    }
  };

  return (
    <ModalBasic
      isOpen={isOpen}
      onClose={onClose}
      width={window.innerWidth > 430 ? "400px" : "312px"}
      height="fit-content">
      <ContentWrapper>
        <TextWrapper>
          <Header>정말 삭제할까요?</Header>
          <Content>{logContent}</Content>
        </TextWrapper>
        <ButtonWrapper>
          <ButtonStyles type="cancel" onClick={onClose}>
            취소
          </ButtonStyles>
          <ButtonStyles
            type="confirm"
            onClick={deleteLog}
            style={{ color: "#00F370" }}>
            삭제
          </ButtonStyles>
        </ButtonWrapper>
      </ContentWrapper>
    </ModalBasic>
  );
};

const ContentWrapper = styled.div`
  padding: 32px;

  @media (max-width: 430px) {
    padding: 24px;
  }
`;

const Header = styled.div`
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline;

  @media (max-width: 430px) {
    font-size: 16px;
    font-weight: 700;
  }
`;

const Content = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 16px 0px;

  /* 텍스트를 최대 3줄까지만 표시 */
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @media (max-width: 430px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

const TextWrapper = styled.div`
  width: 336px;
  height: auto; /* 텍스트 내용에 따라 크기 변화 */

  @media (max-width: 430px) {
    width: 261px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ButtonStyles = styled.button`
  margin-left: 8px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 6px;
  background: ${(props) => (props.type === "cancel" ? "#F2F2F2" : "#000")};
  color: ${(props) => (props.type === "cancel" ? "#000" : "#FFF")};

  width: 70px;
  height: 40px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;

  &:disabled {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.3);
    cursor: not-allowed;
  }

  @media (max-width: 430px) {
    font-size: 14px;
  }
`;

export default ModalDeleteCheck;
