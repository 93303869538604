import React from "react";
import styled from "styled-components";
import ModalBasic from "./ModalBasic.jsx";

// ModalBasic 컴포넌트를 세분화한 문의하기 제출 알림 모달창 컴포넌트
const ModalSubmitCompleted = ({ isOpen, onClose }) => {
  return (
    <ModalBasic
      isOpen={isOpen}
      onClose={onClose}
      width={window.innerWidth > 430 ? "352px" : "264px"}
      height={window.innerWidth > 430 ? "148px" : "144px"}
      padding={window.innerWidth > 430 ? "24px" : "21px"}>
      {/* Modal 창에 기본적으로 위아래 margin 20px이 있어서 height를 188px 대신 148px로 설정 */}
      <TextWrapper>
        <Header>문의 내용이 정상적으로 제출되었습니다.</Header>
        <Content>
          검토 후 정상 영업일 기준 10일 내 남겨주신 메일로 답신 드리겠습니다.
        </Content>
      </TextWrapper>

      <ButtonWrapper>
        <ButtonStyles
          type="close"
          onClick={onClose}
          style={{ color: "#00F370" }}>
          확인
        </ButtonStyles>
      </ButtonWrapper>
    </ModalBasic>
  );
};

const Header = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline;

  @media (max-width: 430px) {
    font-size: 16px;
  }
`;

const Content = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 12px;

  @media (max-width: 430px) {
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
    margin-right: 27px;
  }
`;

const TextWrapper = styled.div`
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 7px;

  /* 데스크톱 환경에서 호버 시 보이도록 설정 */
  @media (max-width: 430px) {
    margin-top: 5px;
    margin-left: 3px;
    margin-right: 0px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
  margin-top: 18px;

  @media (max-width: 430px) {
    margin-top: 16px;
    margin-right: 3px;
  }
`;

const ButtonStyles = styled.div`
  width: 70px;
  height: 40px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 6px;
  background: ${(props) => (props.type === "cancel" ? "#F2F2F2" : "#000")};
  color: ${(props) => (props.type === "cancel" ? "#000" : "#FFF")};
  /* 텍스트 수직 수평 중앙 */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ModalSubmitCompleted;
