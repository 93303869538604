import React from "react";
import styled from "styled-components";

// 가로 공간 여백을 주는 컴포넌트
const WidthInterval = ({ width }) => {
  return <WidthDiv width={width}></WidthDiv>;
};

const WidthDiv = styled.div`
  height: auto;
  width: ${(props) => props.width}px;
`;

export default WidthInterval;
