import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ModalBasic from "./ModalBasic.jsx";
import ModalPayment from "./ModalPayment.jsx";
import ModalUnauthorizedUser from "./ModalUnauthorizedUser.jsx";
import PayList from "../PayList.jsx";
import no_profile from "../../img/profile.png";
import log_out from "../../img/logout.png";
import close_button from "../../img/close_button.png";
import next_button from "../../img/next_button.png";
import toggle_up from "../../img/toggle_up.svg";
import Text from "../Text.jsx";
import useStore from "../../store.js";
import config from "../../config.js";
import HeightInterval from "../HeightInterval.jsx";
import { gradientBorder } from "../../styles/mixin.js";

// 상태관리(전역 데이터)에 우선 더미데이터
// 에서 '차감 동의' 버튼 누르면

// ModalBasic 컴포넌트를 세분화한 마이페이지 컴포넌트
const ModalMyPage = ({ isOpen, onClose, width, height }) => {
  const SERVER_URI = config.serverUri;
  const navigate = useNavigate();
  const initialRender = useRef(true);

  /* 실제 유저 데이터 */
  const {
    userInfo,
    userLawHistory,
    updateUserInfo,
    updateUserLaw,
    deleteCookieByKey,
  } = useStore();

  // Unauthorized 모달창 상태 변수
  const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);

  /* 충전하기 모달창 */
  const [isPaymentModalOpen, setPaymentModalIsOpen] = useState(false);
  const [isToggleOpen, setIsToggleOpen] = useState(true); // 토글 되기 전까진 일단 true로 설정

  const handleOpenPaymentModal = () => {
    setPaymentModalIsOpen(true);
  };
  const handleClosePaymentModal = () => {
    setPaymentModalIsOpen(false);
  };

  const handleToggle = () => {
    setIsToggleOpen(!isToggleOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isOpen) {
        try {
          await updateUserInfo(setShowUnauthorizedModal); // 비동기 함수 호출이라 별도의 비동기 함수 fetchData를 두고 호출
          await updateUserLaw();
        } catch (error) {
          console.error("Failed to update user info or law history:", error);
        }
      }
    };

    fetchData(); // 비동기 함수 호출
  }, [isOpen]);

  const handleLogOut = async () => {
    const uri = `/logout`;
    const url = `${SERVER_URI}${uri}`;

    try {
      await fetch(url, { credentials: "include" });
      deleteCookieByKey("lawlow"); // 로그아웃 시 브라우저 쿠키에 저장된 유저 로그인 기록 삭제
      navigate(`/`); // 로그아웃 후 온보딩 페이지로 이동
    } catch (error) {
      console.error("Failed to Logout", error);
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false; // 첫 렌더링 후에 false로 설정
      return;
    }

    if (!showUnauthorizedModal) {
      // Unauthorized 모달이 닫힐 때 로그인 페이지로 리디렉션 수행
      navigate("/");
    }
  }, [showUnauthorizedModal]);

  // 위치 top: 40%, left: 88%
  return (
    <>
      <ModalBasic
        isOpen={isOpen}
        onClose={onClose}
        width={width}
        height={height}
        padding="16px"
        top={window.innerWidth < 600 ? "50%" : "370px"} // 변경 금지. 프로덕션 PC 버전에서는 370px으로 해야 예상한 디자인대로 나옴
        right="-115px"
        left="auto"
        className="modal-my-page-content">
        <Wrapper>
          <ButtonStyles onClick={onClose}>
            <img
              src={close_button}
              alt="close-button"
              width="32px"
              height="32px"
            />
          </ButtonStyles>
          <StyledImageWrapper>
            <StyledImage src={no_profile} alt="마이페이지 이미지" />
          </StyledImageWrapper>
          <EmailContainer>
            <Text size="14px" color="rgba(0, 0, 0, 0.60)" weight="500">
              {userInfo?.nickname?.trim()
                ? userInfo.nickname
                : userInfo?.email
                ? userInfo.email
                : "사용자"}
              {/* nickname이 없는 경우 email로, email이 없는 경우 대비하여 "사용자" 반환 */}
            </Text>
            <img
              src={log_out}
              width="14px"
              height="14px"
              style={{ marginLeft: "4px", cursor: "pointer" }}
              onClick={() => handleLogOut()}
            />
          </EmailContainer>
          <HrStyles />
          <ModalPayment
            isOpen={isPaymentModalOpen}
            onClose={handleClosePaymentModal}
            title="로우 충전하기"
          />
          <ChargeText onClick={() => handleOpenPaymentModal(true)}>
            충전하기
            <img
              src={next_button}
              width="3px"
              height="7px"
              style={{ marginLeft: "4px" }}
            />
          </ChargeText>
          <LawInfo>
            <Text size="32px" color="#00F370" weight="400" height="24px">
              {userInfo && userInfo.creditAmount != null
                ? userInfo.creditAmount
                : 0}
            </Text>
            <Text size="12px" color="rgba(0, 0, 0, 0.30)" weight="500">
              보유 로우
            </Text>
          </LawInfo>

          <HeightInterval height={8} />
          <HrStyles />

          <LawHistory>
            <StyledText>
              {/* <span onClick={handleToggle}>충전/사용내역</span> */}
              충전/사용내역
              <ToggleImage src={toggle_up} isOpen={isToggleOpen} />
            </StyledText>
            {userLawHistory.length > 0 && (
              <PayList userLawList={userLawHistory} />
            )}
          </LawHistory>
        </Wrapper>
      </ModalBasic>
      {showUnauthorizedModal && (
        <ModalUnauthorizedUser
          isOpen={showUnauthorizedModal}
          onClose={() => setShowUnauthorizedModal(false)}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  padding: 4px;
  white-space: normal; /* 텍스트가 가로 너비를 넘어갈 때 자동 줄바꿈 */
`;

const StyledImageWrapper = styled.div`
  ${gradientBorder}

  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 50%; // 원형 테두리를 유지하기 위해 50%로 설정
  overflow: hidden;
  display: inline-block;
  margin: -15px 104px 0px 105px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  box-sizing: border-box; // 테두리를 포함한 크기 계산
`;

const ButtonStyles = styled.div`
  border: none;
  background: transparent;
  /* 닫기 버튼을 맨 오른쪽으로 붙임 */
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

// 두 요소를 한 라인에 표시
const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 15px;
`;

const LawInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LawHistory = styled.div`
  width: 103%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 10px;
`;

const ToggleImage = styled.img`
  width: 14px;
  height: 6px;
  margin-bottom: 2px;
  margin-left: 2px;
  transition: transform 0.1s ease; // 부드러운 변환 효과 적용
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
`;

const HrStyles = styled.hr`
  flex-shrink: 0;
  border-top: 0.5px rgba(0, 0, 0, 0.1);
  white-space: normal; /* 텍스트가 가로 너비를 넘어갈 때 자동 줄바꿈 */

  width: auto;
  margin: 15px 0px 10px 0px;
`;

const ChargeText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin-right: 1px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  font-style: normal;
`;

export default ModalMyPage;
