import React from "react";

const Text = ({ color, size, weight, height, margin, children }) => {
  return (
    <span
      style={{
        color: color,
        fontSize: size,
        fontWeight: weight,
        lineHeight: height,
        margin: margin,
        fontFamily: "Pretendard",
      }}>
      {children}
    </span>
  );
};

export default Text;
