import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ModalPayCheck from "./Modal/ModalPayCheck.jsx";
import ModalAskError from "./Modal/ModalAskError.jsx";
import {
  Desktop,
  LargeTablet,
  SmallTablet,
  Mobile,
} from "../MediaQuery/QueryPageMediaQuery.js";
import useStore from "../store.js";
import closeButton from "../img/closeButton.png";
import reAskButton from "../img/reAskButton.png";

const ReAskForm = ({ initialTextValue, onClose, height }) => {
  let [textValue, setTextValue] = useState(initialTextValue || ""); // 초기 텍스트 값 설정
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // 입력 필드 글자 입력에 따른 버튼의 활성화 여부를 관리
  const [showModal, setShowModal] = useState(false); // 질문 검색 5로우 차감 확인용 Modal창
  const [showAskErrorModal, setShowAskErrorModal] = useState(false); // 질문하기 에러 알림 Modal창

  const {
    fetchPostRequest,
    setLoading,
    setTextValue: setStoreTextValue,
  } = useStore();

  useEffect(() => {
    setTextValue(initialTextValue);
    setIsButtonDisabled(initialTextValue.length === 0); // 초기 텍스트 값이 비어 있는지 확인하여 버튼 상태 설정
  }, [initialTextValue]);

  const onInputHandler = (e) => {
    const text = e.target.value;
    setTextValue(text); // 입력된 텍스트 업데이트
    setIsButtonDisabled(text.length === 0); // 입력된 텍스트가 없으면 버튼 비활성화
  };

  // 질문시 10로우 차감 확인용 모달창
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleConfirmModal = () => {
    // 사용자가 충분한 로우 개수를 가진 상태에서 결제 동의를 했을 경우 실행
    setShowModal(false);
    setLoading(true);
    postTextToServer();
  };

  // 질문하기 요청중 오류 알림 모달창 띄우기
  const handleShowAskErrorModal = () => {
    //console.log("이전 handleShowAskErrorModal");
    setShowAskErrorModal(true);
    //console.log("이후 handleShowAskErrorModal");
  };

  // 사용자가 입력한 text를 body에 담아 서버에 전송
  const navigate = useNavigate();
  const postTextToServer = async () => {
    try {
      setStoreTextValue(textValue); // postTextToServer 실행하자마자 store에 textValue 저장

      const response = await fetchPostRequest("/private/query", {
        content: textValue,
      });
      // const responseData = await response.json(); // Error 암 발생 원인 // response이 이미 json
      if (response.error) {
        // !response.ok 안 돼서 대책
        throw new Error("Error : 질문 응답 객체 에러");
      }

      if (!response.inquiries) {
        // responseData에 inquiries가 존재하는지 확인
        // 없으면 Error
        throw new Error("Error : 응답 데이터에 inquiries가 없습니다.");
      }

      /*  성공시 서버로부터 response 오면 QueryPage로 이동 */
      setLoading(false);
      // 질문 오류가 났는데(질문 내역에 남지x) 로우가 차감되어있음
      // 추가 - 여기(정상 로직)에 response가 잘 왔다는 신호를 서버에 호출하나 보내기(에러일 경우에 api 호출 하는 것보다 나을듯)
      // 이 프론트측 호출이 확인 된 후에! 서버는 로우 감소시켜야함
      // 그 후 결과페이지로 navigate
      navigate(`/main/q/${response.log_id}`);
    } catch (error) {
      /* 실패시 */
      // alert("질문하기 요청 중 오류가 났습니다. 다시 질문 해주십시오.");
      // 추후 - LAWLOW-267 질문 오류 팝업창
      //console.log("이전 catch");
      handleShowAskErrorModal(); // 오류 알림 실행
      //console.log("이후 catch");
      // 그 상태 그대로 있게 하기위해 주석처리
      // navigate(`/main`, { state: { textValue } }); // 메인 페이지로 이동하며 textValue 전달
    }
  };

  // 질문 버튼 눌렀을 때 실행되는 함수
  const handleQueryAction = () => {
    if (isButtonDisabled) {
      alert("내용을 입력하세요."); // 내용이 없을 때 경고 메시지 표시
      return;
    }
    // store에 textValue 저장
    setStoreTextValue(textValue);
    // 다른 동작 수행 가능
    handleOpenModal();
    // api 질문 동작
  };

  const handleCloseAskErrorModal = () => {
    // 오류 알림을 사용자가 닫은 후에 요청했던 페이지 그대로 돌아갈 수 있도록 설정
    setShowAskErrorModal(false); // 모달 닫기
    setLoading(false);
  };

  // 작업 중 나가려할 때 기본 브라우저 기능 실행
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (textValue.trim() !== "") {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [textValue]);

  return (
    <>
      <Wrapper>
        <WrapperForm height={height}>
          <Desktop>
            <HeaderDesktop>
              <Title>내 상황 수정 및 재검색</Title>
              <ButtonWrapper>
                <ButtonImage
                  src={closeButton}
                  alt="수정 그만하기"
                  onClick={onClose}
                />
                <ButtonImage
                  src={reAskButton}
                  alt="재검색 버튼"
                  onClick={handleQueryAction}
                  disabled={isButtonDisabled}
                />
              </ButtonWrapper>
            </HeaderDesktop>
          </Desktop>

          <LargeTablet>
            <HeaderDesktop>
              <Title>내 상황 수정 및 재검색</Title>
              <ButtonWrapper>
                <ButtonImage
                  src={closeButton}
                  alt="수정 그만하기"
                  onClick={onClose}
                />
                <ButtonImage
                  src={reAskButton}
                  alt="재검색 버튼"
                  onClick={handleQueryAction}
                  disabled={isButtonDisabled}
                />
              </ButtonWrapper>
            </HeaderDesktop>
          </LargeTablet>

          <SmallTablet>
            <HeaderDesktop>
              <Title>내 상황 수정 및 재검색</Title>
              <ButtonWrapper>
                <ButtonImage
                  src={closeButton}
                  alt="수정 그만하기"
                  onClick={onClose}
                />
                <ButtonImage
                  src={reAskButton}
                  alt="재검색 버튼"
                  onClick={handleQueryAction}
                  disabled={isButtonDisabled}
                />
              </ButtonWrapper>
            </HeaderDesktop>
          </SmallTablet>

          <InputField
            type="text"
            placeholder="문제 상황을 자세하게 입력해주세요."
            onChange={onInputHandler}
            value={textValue}
          />
          <Mobile>
            <ButtonWrapper>
              <ButtonImage
                src={closeButton}
                alt="수정 그만하기"
                onClick={onClose}
              />
              <ButtonImage
                src={reAskButton}
                alt="재검색 버튼"
                onClick={handleQueryAction}
                disabled={isButtonDisabled}
              />
            </ButtonWrapper>
          </Mobile>
        </WrapperForm>
        <ModalAskError
          isOpen={showAskErrorModal}
          onClose={handleCloseAskErrorModal}
        />
        <ModalPayCheck
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={handleConfirmModal}
          law={10}
          buttonType="queryButton"
          headerContent="재검색하기"
          additionalContent={
            <span
              dangerouslySetInnerHTML={{
                __html:
                  "사용자의 상황과 유사한 상위 5개의 판례를 검색 결과로<br />제공하며, 검색 시마다 다른 결과를 얻을 수 있습니다.",
              }}
            />
          }
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  // 회색폼
  width: 680px;
  height: auto;
  background-color: #f9f9f9;
  border-radius: 24px;

  @media (max-width: 1140px) and (min-width: 745px) {
    width: 680px;
  }

  @media (max-width: 744px) and (min-width: 481px) {
    width: 417px;
  }

  @media (max-width: 480px) {
    width: 312px;
    margin-top: 36px;
    margin-bottom: 24px;
  }
`;

const WrapperForm = styled.div` // 그라데이션폼
  margin: 6px;
  width: auto;
  height: ${({ height }) =>
    height && height < 222
      ? `${height}px`
      : "222px"}; /* 최대 높이는 222px로 설정 */
  border-radius: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;

  /* 그라데이션 */
  // gradientBorder 안 쓰고 일부러 custom함 - deg, %
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit; /* 부모의 border-radius를 상속받음 */
    padding: 0.07rem; /* 테두리 두께 */
    background: linear-gradient(90deg, #00f370 50%, #0057ff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none; /* 클릭 이벤트를 받지 않도록 설정 */
  }

  // 위와 주석 처리
  // @media (max-width: 1140px) and (min-width: 745px) {}

  // @media (max-width: 744px) and (min-width: 481px) {}

  @media (max-width: 480px) {
    margin: 5px; 6px;
  }
`;

const HeaderDesktop = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 18px 26px 0px 26px;
`;

const Title = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 480px) {
    justify-content: flex-end;
    padding-right: 12px;
    padding-bottom: 15px;
  }
`;

const ButtonImage = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const InputField = styled.textarea`
  flex-grow: 1;
  border: none;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
  margin: 10px 14px 22px 26px; // 스크롤 위치로 인해 14px로 설정
  padding: 0; /* textarea 기본 설정된 padding 값 제거 */
  resize: none;
  overflow-y: auto;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  @media (max-width: 480px) {
    margin: 19px 7px 12px 12px;
  }
`;

export default ReAskForm;
