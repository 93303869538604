import React, { useState, useEffect } from "react"; // useState와 useEffect 추가
import { styled } from "styled-components";
import { Progress } from "antd";
import HeightInterval from "../HeightInterval";

const useScreenWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
};

/* 질문 검색 시 Loading 컴포넌트에 포함되는 ProgressBar */
const DataLoader = ({ progress }) => {
  // progress를 prop으로 받음
  const screenWidth = useScreenWidth(); // 화면 너비 추적을 위해 useScreenWidth 훅 사용

  return (
    <div style={{ width: "290px", margin: "0 auto", textAlign: "center" }}>
      <Text>잠시만 기다려주세요 ({Math.round(progress)}%)</Text>

      <HeightInterval height={screenWidth >= 600 ? 36 : 35} />

      <StyledProgress
        percent={Math.round(progress)}
        showInfo={false}
        strokeColor={{
          "0%": "#00F370",
          "100%": "#3A7DFF",
        }}
      />
    </div>
  );
};

const Text = styled.div`
  color: rgba(0, 0, 0, 0.3);
  text-align: center;
  font-weight: 500;
  font-style: normal;

  @media (max-width: 10000px) and (min-width: 600px) {
    font-size: 18px;
    line-height: 30px;
  }

  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 23px;
  }
`;

/* ProgressBar 길이 조절 */
const StyledProgress = styled(Progress)`
  @media (max-width: 10000px) and (min-width: 600px) {
    width: 290px !important;
    height: 8px !important;
  }

  @media (max-width: 599px) {
    width: 240px !important;
    height: 6px !important;
  }
`;

export default DataLoader;
