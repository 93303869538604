// 공통 css
import { css } from "styled-components";

export const gradientBorder = css`
  /* 그라데이션 */
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit; /* 부모의 border-radius를 상속받음 */
    padding: 0.07rem; /* 테두리 두께 */
    background: linear-gradient(to bottom right, #00f370 30%, #0057ff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none; /* 클릭 이벤트를 받지 않도록 설정 */
  }
`;
