import React from "react";
import styled from "styled-components";

// 위아래 공간 여백을 주는 컴포넌트
const HeightInterval = ({ height, minHeight, maxHeight }) => {
  // minHeight와 maxHeight가 모두 존재할 경우 동적으로 해상도 높이를 고려하여 계산된 높이 값(*0.02)을 사용
  const dynamicHeight =
    minHeight !== undefined && maxHeight !== undefined
      ? Math.min(maxHeight, Math.max(minHeight, window.innerHeight * 0.02))
      : height;

  return <HeightDiv height={dynamicHeight}></HeightDiv>;
};

const HeightDiv = styled.div`
  width: auto;
  height: ${(props) => props.height}px;
`;

export default HeightInterval;
