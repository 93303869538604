import React from "react";
import styled from "styled-components";
import logo from "../img/logo.png";
import { useNavigate, useLocation } from "react-router-dom";

const Logo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // 로고 클릭 시 페이지 이동
  const moveToPage = () => {
    const path = location.pathname;

    if (path === "/" || path === "/main") {
      // 현재 경로가 '/' 또는 '/main'인 경우 페이지 새로고침
      window.location.reload();
    } else if (path.startsWith("/main/q/")) {
      // 현재 경로가 '/main/q'로 시작하는 경우 '/main'으로 이동
      navigate("/main");
    }
  };

  return (
    <ImgWrapper onClick={moveToPage}>
      <LogoImg src={logo} alt="logo" />
    </ImgWrapper>
  );
};

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const LogoImg = styled.img`
  height: auto;

  @media (max-width: 10000px) and (min-width: 1141px) {
    width: 153px;
  }

  @media (max-width: 1140px) and (min-width: 745px) {
    width: 153px;
  }

  @media (max-width: 744px) and (min-width: 481px) {
    width: 114px;
    // width: 148.2px;
  }

  @media (max-width: 480px) {
    width: 114px;
    // width: 148.2px;
  }
`;

export default Logo;
