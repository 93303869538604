import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Mobile } from "../MediaQuery/OnboardingPageMediaQuery.js";
import HeightInterval from "../Components/HeightInterval.jsx";
import Button from "./Button.jsx";
import ReAskForm from "./ReAskForm.jsx";
import toggle_down from "../img/toggle_down.png";
import prevButtonImg from "../img/prevButtonImg.svg";
import nextButtonImg from "../img/nextButtonImg.svg";
import copyButton from "../img/copyButton.png";
import rewriteButton from "../img/rewriteButton.png";
import useStore from "../store.js";

// 컴포넌트 디자인 확인용 페이지
const ToggleDropdown = ({ id }) => {
  const { situation } = useStore();
  const [isEditing, setIsEditing] = useState(false);

  const [isContentVisible, setIsContentVisible] = useState(false);
  const handleToggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  /* 페이지네이션 */
  // 페이지 이동 버튼 클릭할때 현재 페이지를 업데이트
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);

  const handleNextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  /* SituationBox에서 버튼이 눌렸을 때 처리 */
  const handleCopyClick = () => {
    const textToCopy = `${situation}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("판례 내용이 복사되었습니다");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleRewriteClick = () => {
    setIsEditing(true);
  };

  // URL이 변경될 때 isEditing 상태를 false로 초기화
  useEffect(() => {
    setIsEditing(false);
  }, [id]);

  useEffect(() => {
    if (situation) {
      const words = situation.split(" ");
      const pageSize = 100; // 페이지당 단어 수 (적절하게 조정)
      const newPages = [];
      for (let i = 0; i < words.length; i += pageSize) {
        newPages.push(words.slice(i, i + pageSize).join(" "));
      }
      setPages(newPages);
      setCurrentPage(0);
    }
  }, [situation]);

  return (
    <Mobile>
      <Container>
        <Overlay>
          <ToggleButton onClick={handleToggleContent}>
            <ToggleText>내 상황 펼쳐보기</ToggleText>
            <ToggleIcon src={toggle_down} isContentVisible={isContentVisible} />
          </ToggleButton>
        </Overlay>
        <ContentContainer isVisible={isContentVisible}>
          {isContentVisible &&
            (isEditing ? (
              <ReAskForm
                initialTextValue={situation && situation} // 만약 오류(흰화면) 날거면 여기일거임. 원래 inquires[0] 을 썼었음
                onClose={() => setIsEditing(false)}
              />
            ) : (
              <ContentBox>
                {pages.length > 0 && <Content>{pages[currentPage]}</Content>}
                <ButtonWrapper>
                  <ButtonImage
                    src={copyButton}
                    alt="복사하기 버튼"
                    onClick={handleCopyClick}
                  />
                  <ButtonImage
                    src={rewriteButton}
                    alt="수정하기 버튼"
                    onClick={handleRewriteClick}
                  />
                </ButtonWrapper>
                {pages.length > 1 && (
                  <NextPrevButtons>
                    <Button
                      image_src={prevButtonImg}
                      imgWidth={20}
                      imgHeight={20}
                      alt_text="이전 버튼"
                      onClick={handlePrevPage}
                      disabled={currentPage === 0}
                    />
                    <Button
                      image_src={nextButtonImg}
                      imgWidth={20}
                      imgHeight={20}
                      alt_text="다음 버튼"
                      onClick={handleNextPage}
                      disabled={currentPage === pages.length - 1}
                    />
                  </NextPrevButtons>
                )}
              </ContentBox>
            ))}
        </ContentContainer>
      </Container>
    </Mobile>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30; /* ContentContainer 의 z-index 보다는 값이 커야함 */
`;

const ToggleButton = styled.button`
  width: 115px;
  height: 32px;
  border-radius: 24px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToggleIcon = styled.img`
  width: 8px;
  height: 4px;
  transform: ${({ isContentVisible }) =>
    isContentVisible ? "rotate(-180deg)" : "rotate(0deg)"};
  transition: transform 0.1s ease;
`;

const ToggleText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 500;
  margin-right: 4px;
`;

const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);
  border-bottom: ${({ isVisible }) => (isVisible ? "0.5px" : "0")} solid
    rgba(0, 0, 0, 0.2);
  background: #fff;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentBox = styled.div`
  width: 312px;
  height: auto;
  flex-shrink: 0;
  border-radius: 24px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  margin: 36px 24px 24px 24px;
`;

const Content = styled.div`
  margin: 24px 18px 18px 16px;
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;
`;

const NextPrevButtons = styled.div`
  display: flex;
  width: auto;
  height: 32px;
  margin-left: auto;
  margin-right: 16px;
  margin-bottom: 18px;
  gap: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 18px;
  margin-bottom: 16px;
`;

const ButtonImage = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export default ToggleDropdown;
