import React from "react";
import styled from "styled-components";
import privacyPolicyDataRaw from "../../data/privacyPolicy.json";
import ModalBasic from "./ModalBasic.jsx";
import close_button from "../../img/close_button.png";

const privacyPolicyData = JSON.parse(
  JSON.stringify(privacyPolicyDataRaw),
  (key, value) =>
    typeof value === "string" ? value.replace(/\\n/g, "<br>") : value
);

// ModalBasic 컴포넌트를 세분화한 개인정보 처리방침 컴포넌트
const ModalPrivacyPolicy = ({ isOpen, onClose }) => {
  return (
    <ModalBasic
      isOpen={isOpen}
      onClose={onClose}
      width={window.innerWidth > 599 ? "550px" : "312px"}
      height={window.innerWidth > 599 ? "733px" : "600px"}>
      {/* height 770px에서 733px로 임의로 변경 */}
      {/* 추후 - 모달 height와 WrapperContents의 margin값 디자인 변경 필요  */}
      {/* Hr이 모달창 양끝에 닿게하기 위해 WrapperHeader HrStyles WrapperContents 구조로 변경 */}

      <WrapperHeader>
        <Header>{privacyPolicyData.title}</Header>
        <ButtonStyles onClick={onClose}>
          <img src={close_button} alt="close-button" />
        </ButtonStyles>
      </WrapperHeader>

      <HrStyles />

      <WrapperContents>
        <ScrollableContent>
          <RuleWrapper>
            {privacyPolicyData.rules.map((rule, index) => (
              <div key={index}>
                <Title hasId={!!rule.rule_id}>{rule.rule_title}</Title>
                {rule.contents.map((content, index) => (
                  <div key={index}>
                    <Content
                      dangerouslySetInnerHTML={{ __html: content.main_content }}
                    />
                    {content.sub_contents &&
                      content.sub_contents.length > 0 && (
                        <StyledUl>
                          {content.sub_contents.map((subContent, subIndex) => (
                            <li key={subIndex}>
                              <Content
                                dangerouslySetInnerHTML={{ __html: subContent }}
                              />
                            </li>
                          ))}
                        </StyledUl>
                      )}
                  </div>
                ))}
              </div>
            ))}
          </RuleWrapper>
        </ScrollableContent>
      </WrapperContents>
    </ModalBasic>
  );
};

const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  margin: 24px 24px 25px;

  @media (max-width: 599px) {
    margin: 20px 20px 17px;
  }
`;

const Header = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* 텍스트 중앙에 배치하기 */
  width: 100%;
  text-align: center;
  display: inline-block;

  @media (max-width: 599px) {
    font-size: 16px;
  }
`;

const Title = styled.div`
  color: ${(props) => (props.hasId ? "rgba(0, 0, 0, 0.60)" : "#000")};
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;

  &:before {
    content: ${(props) => (props.hasId ? "counter(rule-counter) '. '" : "''")};
    counter-increment: ${(props) => (props.hasId ? "rule-counter" : "none")};
  }

  @media (max-width: 599px) {
    margin-top: 16px;
  }
`;

const RuleWrapper = styled.div`
  counter-reset: rule-counter;
`;

const Content = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
`;

const StyledUl = styled.ul`
  padding-left: 16px;
  margin: 0;
  margin-left: 5px;
`;

const HrStyles = styled.hr`
  width: 99.5%; // 100%로 하면 가로세로 스크롤이 생김
  padding: 0;
  margin: 0;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
`;

const ButtonStyles = styled.div`
  border: none;
  background: transparent;
  display: flex;
  position: absolute;
  right: 0px;
  width: 32px;
  height: 32px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 599px) {
    width: 24px;
    height: 24px;
  }
`;

const WrapperContents = styled.div`
  margin: 12px 11px 24px 32px;

  @media (max-width: 599px) {
    margin: 20px 7px 29px 20px;
  }
`;

const ScrollableContent = styled.div`
  // Contents 차지 height는 총 652px
  //width: 103%; /* 스크롤바의 오른쪽 간격 10px 맞추기 위함 */
  height: 620px; // padding-top 32px을 뺀 간격
  padding-right: 12px;
  overflow-y: auto;

  /* 스크롤바 스타일링 */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  @media (max-width: 599px) {
    // Contents 차지 height는 총 510px
    //width: 103%;
    height: 490px;
    padding-right: 7px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 81px;
    }
  }
`;

export default ModalPrivacyPolicy;
