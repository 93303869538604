import React from "react";
import Log from "../Components/Log.jsx";
import styled from "styled-components";

const LogList = ({ logs }) => {
  // 최신순이 먼저 보이게 하기 위해 logs 배열을 복사하고 역순으로 정렬
  const reversedLogs = [...logs].reverse();

  return (
    <Wrapper>
      {reversedLogs.map((log) => {
        return <Log key={log.id} id={log.id} content={log.content}></Log>;
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 650px; /* 질문 입력 필드의 맨밑 line과 이용약관 밑 line이 일직선 되도록 설정*/
  overflow-y: auto;
  margin-right: 7px; // 스크롤바 위치 설정(10px 기준은 스크롤바 중앙에서부터)

  /* 스크롤바 스타일링 */
  &::-webkit-scrollbar {
    width: 6px; // 스크롤바 너비
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
`;

export default LogList;
