import React, { useEffect, useState, useRef } from "react";
import ModalBasic from "./ModalBasic.jsx";
import styled from "styled-components";
import useStore from "../../store.js";
import config from "../../config.js";
import { createGlobalStyle } from "styled-components";
import ModalLawCharge from "./ModalLawCharge.jsx";
import ModalUnauthorizedUser from "./ModalUnauthorizedUser.jsx";
import { useNavigate } from "react-router-dom";

// ModalBasic 컴포넌트를 세분화한 삭제 재확인 컴포넌트

/* 
buttonType prop
- "queryButton" : 질문 버튼(10로우 차감)
- "comparisonButton" : 비교 버튼(2로우 차감)
 */
const ModalPayCheck = ({
  isOpen,
  onClose,
  onConfirm,
  law,
  buttonType,
  headerContent,
  additionalContent,
}) => {
  const [showModal, setShowModal] = useState(false); // 로우 부족 알림 Modal창
  const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false); // Unauthorized 모달창 상태 변수

  const SERVER_URI = config.serverUri;
  const navigate = useNavigate();
  const initialRender = useRef(true);
  const { userInfo, updateUserInfo, deleteCookieByKey } = useStore();

  // 결제 버튼을 눌렀을 때 로우 개수 확인
  const handleConfirm = async () => {
    if (!userInfo) {
      // 알림 후 재시도
      window.alert(
        "사용자 정보가 없어 결제를 할 수 없습니다. 재로그인 후 다시 시도해주십시오."
      );
      return;
    }

    if (
      (buttonType === "queryButton" && userInfo.creditAmount >= 10) ||
      (buttonType === "comparisonButton" && userInfo.creditAmount >= 2)
    ) {
      // '질문하기' 팝업의 '확인' 버튼이 눌리고 로우 개수가 10개 이상일 때
      // '비교하기' 팝업의 '확인' 버튼이 눌리고 로우 개수가 2개 이상일 때
      // 서버에 결제 진행 및 데이터 요청
      await onConfirm();

      // 결제 진행 후 로우 개수(creditAmount) 업데이트를 위해
      try {
        await updateUserInfo(setShowUnauthorizedModal); // store의 updateUserInfo 함수를 호출하여 userInfo를 업데이트
      } catch (error) {
        window.alert("결제 과정에서 문제가 발생했습니다. 다시 시도해 주세요.");
        console.error("Error during payment and user info update:", error);
      }
    } else {
      // 로우 개수가 부족할 때
      onClose(); // 이 Modal창은 닫고
      setShowModal(true); // 로우 부족 알림 Modal창 Open
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false; // 첫 렌더링 후에 false로 설정
      return;
    }

    if (!showUnauthorizedModal) {
      // Unauthorized 모달이 닫힐 때 로그인 페이지로 리디렉션 수행
      navigate("/");
    }
  }, [showUnauthorizedModal]);

  // 위치 top: 50%, left: 50%
  return (
    <>
      <GlobalStyle />
      <ModalBasic
        isOpen={isOpen}
        onClose={onClose}
        width={window.innerWidth > 430 ? "400px" : "312px"}
        height={window.innerWidth > 430 ? "auto" : "auto"}
        top="50%"
        left="50%"
        className="modal-pay-check-content">
        <Wrapper>
          <TextWrapper>
            <Header>{headerContent}</Header>
            <Content>
              {law}로우가 차감됩니다.
              <br />
              계속 진행하시겠습니까?
            </Content>
            {additionalContent && (
              <AdditionalContent>{additionalContent}</AdditionalContent>
            )}
          </TextWrapper>
          <ButtonWrapper buttonType={buttonType}>
            <ButtonStyles onClick={onClose} type="cancel">
              취소
            </ButtonStyles>
            <ButtonStyles
              onClick={handleConfirm}
              type="confirm"
              style={{ color: "#00F370" }}>
              <div
                style={{
                  border: "1px solid rgba(0, 243, 112, 0.3)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "calc(100% - 4px)",
                  height: "calc(100% - 4px)",
                  borderRadius: "6px",
                }}>
                확인
              </div>
            </ButtonStyles>
          </ButtonWrapper>
        </Wrapper>
      </ModalBasic>
      <ModalLawCharge isOpen={showModal} onClose={() => setShowModal(false)} />
      {showUnauthorizedModal && (
        <ModalUnauthorizedUser
          isOpen={showUnauthorizedModal}
          onClose={() => setShowUnauthorizedModal(false)}
        />
      )}
    </>
  );
};

/* Mobile - top:48% left:50% */
const GlobalStyle = createGlobalStyle`
  @media (max-width: 430px) {
    .modal-pay-check-content {
      top: 48% !important;
      left: 50% !important;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;

  @media (max-width: 430px) {
    padding: 24px;
  }
`;

const Header = styled.div`
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: inline;
`;

const Content = styled.div`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-top: 8px;

  @media (max-width: 430px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const AdditionalContent = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 8px;

  @media (max-width: 430px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const TextWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 12px;

  /* 모바일 환경에서는 항상 표시 */
  @media (max-width: 430px) {
    margin-top: ${(props) =>
      props.buttonType === "queryButton" ? "16px" : "12px"};
  } // 검색하기 버튼일 때 16px, 비교하기 버튼일 때 12px
`;

const ButtonStyles = styled.div`
  width: 70px;
  height: 40px;
  margin-left: 8px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 6px;
  background: ${(props) => (props.type === "cancel" ? "#F2F2F2" : "#000")};
  color: ${(props) => (props.type === "cancel" ? "#000" : "#FFF")};
  /* 버튼 텍스트 수직 수평 정중앙 */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default ModalPayCheck;
