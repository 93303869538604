import React from "react";
import styled from "styled-components";
import ModalBasic from "./ModalBasic.jsx";

const ModalUnauthorizedUser = ({ isOpen, onClose }) => {
  const handleConfirm = () => {
    onClose();
  };

  return (
    <ModalBasic
      isOpen={isOpen}
      onClose={onClose}
      width={window.innerWidth > 430 ? "352px" : "266px"}
      height={window.innerWidth > 430 ? "134px" : "111px"}
      padding={window.innerWidth > 430 ? "24px" : "23px"}>
      <ContentWrapper>
        <TextWrapper>
          세션이 만료되어 사용자 정보가 없습니다.
          <br />
          다시 로그인 해주세요.
        </TextWrapper>
        <ButtonWrapper>
          <ButtonStyles
            type="confirm"
            onClick={handleConfirm}
            style={{ color: "#00F370" }}>
            확인
          </ButtonStyles>
        </ButtonWrapper>
      </ContentWrapper>
    </ModalBasic>
  );
};

const ContentWrapper = styled.div`
  color: #000;
  /* padding 24px 라는걸 전제로 조정한 margin값 */

  @media (max-width: 430px) {
    /* padding 23px 라는걸 전제로 조정한 margin값 */
    margin-top: 3px;
    margin-left: 1px;
    margin-right: 1px;
  }
`;

const TextWrapper = styled.div`
  margin-top: 8px;
  margin-left: 8px;
  margin-bottom: 18px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  @media (max-width: 430px) {
    margin-top: 3px;
    margin-left: 1px;
    margin-bottom: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 8px;

  @media (max-width: 430px) {
    margin-top: 16px;
    margin-right: 1px;
  }
`;

const ButtonStyles = styled.button`
  padding: 8px 16px;
  margin-left: 8px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 6px;
  background: ${(props) => (props.type === "cancel" ? "#F2F2F2" : "#000")};
  color: ${(props) => (props.type === "cancel" ? "#000" : "#FFF")};
  width: 70px;
  height: 40px;

  &:disabled {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.3);
    cursor: not-allowed;
  }
`;

export default ModalUnauthorizedUser;
