import React, { useState } from "react";
import styled, { css } from "styled-components";
import CaseSummary from "./CaseSummary.jsx";
import CaseComparison from "./CaseComparison.jsx";
import { gradientBorder } from "../styles/mixin.js";

const ComparisonForm = () => {
  const wrapperHeight = 745; // 전체 Wrapper 높이

  return (
    <Wrapper>
      <Header wrapperHeight={wrapperHeight}>
        <Number>넘버 {/* 판결문 순서 - 서버 response data */}</Number>
        <CaseTitle>
          헌법재판소
          <br />
          안전대상 {/* 재판 번호 - 서버 response data */}
        </CaseTitle>
        <Relevance> 관련도{/* 관련도 - 서버 response data */}</Relevance>
      </Header>
      <StyledHr />
      <CaseSummary wrapperHeight={wrapperHeight}>
        요약 내용{/* 컴포넌트화*/}
      </CaseSummary>
      <StyledHr />
      <CaseComparison></CaseComparison>
    </Wrapper>
  );
};
/* ComparsionForm.jsx와 ResultForm.jsx 의 Header 중복 컴포넌트화 하기 */
/*  ComparisonForm 높이 비율 = Header : CaseSummary : CaseComparison = 10% : 41% : 49%  */
const Wrapper = styled.div`
  ${gradientBorder}

  /* 이후 width, height %로 변경 */
  width: 680px;
  height: 745px;
  flex-shrink: 0;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2px; /* 테두리 두께 */
`;

const Header = styled.div`
  /* 부모 Wrapper 높이 중 10% 차지 */
  height: ${(props) => props.wrapperHeight * 0.1}px;
  display: flex;
`;
const Number = styled.div`
  height: auto;
  min-width: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CaseTitle = styled.div`
  min-width: 77%;
  min-height: 41%;
  display: flex;
  align-items: center;
`;
const Relevance = styled.div`
  min-width: auto; /* 12% */
  display: flex;
  align-items: center;
`;

// 추가된 스타일
const StyledHr = styled.hr`
  border: none; /* 기본 테두리 제거 */
  height: 2px; /* 높이 설정 */
  background-color: #f1f1f1; /* 배경색 지정 */
  margin: 0; /* 위아래 여백 설정 */
`;

export default ComparisonForm;
