import React, { useState } from "react";
import styled from "styled-components";
import copy from "../img/copy.png";
import search from "../img/search.png";
import CaseSummary from "./CaseSummary.jsx";
import Text from "../Components/Text.jsx";
import Button from "../Components/Button.jsx";
import HeightInterval from "../Components/HeightInterval.jsx";
import CaseComparison from "../Components/CaseComparison.jsx";
import ModalPayCheck from "./Modal/ModalPayCheck";
import RelevanceChart from "../Components/ApexChart/RelevanceChart.jsx";
import ai from "../img/ai.png";
import dots_png from "../img/dots_png.png";
import dots_gif from "../img/dots_gif.gif";
import TooltipComponent from "./Tooltip";
import { gradientBorder } from "../styles/mixin.js";

/* ResultForm Desktop 버전 */
const ResultForm = ({
  index,
  courtName,
  sentencingData,
  caseNumber,
  caption,
  relevance,
  question,
  detail,
  answer,
  caseId,
  isComparison,
  commonality,
  difference,
}) => {
  const [showCompare, setShowCompare] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // CaseComparison의 props로부터 추가된 로딩 상태

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleConfirmModal = () => {
    setShowModal(false); // 차감? 묻는 모달 닫기
    setShowCompare(true); // CaseComparison 보여주기
  };

  // 구글에 원문 검색
  const handleSearch = () => {
    const query = `${courtName} ${caseNumber}`;
    const url = `https://www.google.com/search?q=${encodeURIComponent(query)}`;
    window.open(url, "_blank");
  };

  // 복사하기
  const handleCopy = () => {
    const textToCopy = `
[판례 번호]
${courtName} ${sentencingData} ${caseNumber}

[문제]
${question}

[결론]
${answer}

[내용]
${detail}
    `;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("판례 내용이 복사되었습니다");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <Wrapper>
      <Header>
        <Number>#{index + 1}</Number>
        <CaseTitle>
          {courtName} {sentencingData} {caseNumber}
          <br />
          {caption}
        </CaseTitle>
        <Relevance>
          <RelevanceChart relevance={relevance} />
          <Text
            size="10px"
            color="rgba(0, 0, 0, 0.30)"
            weight="400"
            lineHeight="14px">
            관련도
          </Text>
        </Relevance>
      </Header>
      <HrStyles />
      <HeightInterval height={24} />
      <CaseSummary
        question={question}
        detail={detail}
        answer={answer}></CaseSummary>
      <HeightInterval height={41} />
      {showCompare || isComparison ? (
        <>
          <AiHrStyles />
          <WrapperComparison>
            <Overlay>
              <AiDiv>
                <img src={ai} width="21px" height="21px" alt="AI" />
                <AiText>내 상황과 비교하기</AiText>
                {isLoading ? ( // 로딩 중일 때 GIF
                  <img
                    src={dots_gif}
                    width="20px"
                    height="20px"
                    alt="Dots Gif"
                  />
                ) : (
                  // 로딩 완료 후 PNG
                  <img
                    src={dots_png}
                    width="20px"
                    height="20px"
                    alt="Dots Png"
                  />
                )}
              </AiDiv>
            </Overlay>
            <CaseComparison
              caseId={caseId}
              isComparison={isComparison}
              commonality={commonality}
              difference={difference}
              setLoading={setIsLoading} // 로딩 상태 전달
              onCopy={handleCopy} // handleCopy 함수를 전달
              onSearch={handleSearch} // handleSearch 함수를 전달
            />
          </WrapperComparison>
        </>
      ) : (
        <>
          <HrStyles />
          <Bottom>
            <Button
              image_src={copy}
              alt_text="복사하기"
              button_text="복사하기"
              paddingRight="8px"
              fontColor="rgba(0, 0, 0, 0.6)"
              fontSize="12px"
              fontWeight="500"
              lineHeight="26px"
              imgWidth={32}
              imgHeight={32}
              onClick={handleCopy}></Button>
            <Button
              image_src={search}
              alt_text="원문검색"
              button_text="원문검색"
              paddingRight="8px"
              fontColor="rgba(0, 0, 0, 0.6)"
              fontSize="12px"
              fontWeight="500"
              lineHeight="26px"
              imgWidth={32}
              imgHeight={32}
              onClick={handleSearch}></Button>
            <WrapperTB>
              <TooltipComponent />
              <ButtonCompare onClick={handleOpenModal}>
                내 상황과 비교하기
              </ButtonCompare>
            </WrapperTB>
          </Bottom>
        </>
      )}
      <ModalPayCheck
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleConfirmModal}
        law={2}
        buttonType="comparisonButton"
        headerContent="내 상황과 비교하기"
      />
    </Wrapper>
  );
};
/* CaseSummary 가로 세로 비 */
/*  width 비율 = 11% 81% 8% */
/*  height 비율 = 17% 68% 15% */

/* Header 가로 비 */
/* width 비율 = Number : CaseTitle : CaseSummary =  11% : 77% : 12% */

const Wrapper = styled.div`
  ${gradientBorder}

  width: 680px;
  height: auto;
  flex-shrink: 0;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  height: 100%;
  min-height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Number = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 32px;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

// Desktop 판례 제목은 AI Alert 글씨체와 동일하게
const CaseTitle = styled.div`
  min-width: 75%;
  height: auto;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const Relevance = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 24px;

  @media (max-width: 1141px) and (min-width: 745px) {
    margin-left: 8px;
  }

  @media (max-width: 744px) and (min-width: 481px) {
    margin-left: 20px;
  }

  @media (max-width: 480px) {
    margin-left: 22px;
  }
`;

const Bottom = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  margin-left: 32px;
  gap: 8px;
`;

const ButtonCompare = styled.div`
  width: 192px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(92deg, #00f370 0%, #00badc 50.5%, #0057ff 100%);
  border: none;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  // 질문결과페이지
  @media (max-width: 744px) {
    width: auto;
  }

  // @media (max-width: 480px) {}
`;

const WrapperTB = styled.div`
  display: flex;
  margin-left: auto; /* 맨 오른쪽으로 이동 */
  margin-right: 32px;
  gap: 6px;
  align-items: center;
`;

const HrStyles = styled.hr`
  position: relative;
  width: 100%;
  height: 0px;
  flex-shrink: 0;
  border: 1px solid #f1f1f1;
  margin: 0; /* 상하 마진 제거 */
`;

const AiDiv = styled.div`
  width: 161px;
  height: 35px;
  border-radius: 24px;
  border: 1px solid #f1f1f1;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AiText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: 0px 0px 0px 4px;
`;

const WrapperComparison = styled.div`
  position: relative; /* 부모 요소를 relative로 설정 */
  width: 100%;
  display: flex;
`;

const AiHrStyles = styled.hr`
  border: 1px solid #f1f1f1; /* AiDiv 테두리와 같은 색상 및 두께*/
  margin: 0;
`;

const Overlay = styled.div`
  position: absolute; /* 부모 요소를 기준으로 위치 설정 */
  left: 50%;
  top: -17.5px; /* AiHrStyles 위로 이동하도록 조정 */
  transform: translateX(-50%);
  z-index: 30; /* ContentContainer의 z-index보다 값이 커야함 */

  // 질문페이지 Tablet 범위에서는 겹침이 발생하지 않도록 요소 숨기기
  @media (max-width: 1140px) and (min-width: 600px) {
    display: none;
  }
`;

export default ResultForm;
