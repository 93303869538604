import React, { useState } from "react";
import styled from "styled-components";
import HeightInterval from "../Components/HeightInterval.jsx";

const MAX_CHAR_COUNT = 200; // 내용에 표시할 최대 글자 수

// json 파일 보며 data 내용 변경해두기
/* 데이터 개수만큼 ResultForm 생성(최대 5개) */
const CaseSummary = ({ question, detail, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const getShortDetail = (text, limit) => {
    if (text.length <= limit) {
      return text;
    }
    return text.slice(0, limit) + "...";
  };

  const detailContent = isExpanded
    ? detail
    : getShortDetail(detail, MAX_CHAR_COUNT);

  return (
    <Wrapper>
      <Summary height="auto">
        <Title>문제</Title>
        <Contents>{question}</Contents>
      </Summary>

      <HeightInterval height={16} />

      <Summary height="auto">
        <Title>결론</Title>
        <Contents>{answer}</Contents>
      </Summary>

      <HeightInterval height={16} />

      <Summary height="auto">
        <Title>내용</Title>
        <Contents>
          {detailContent}
          {detail.length > 200 && (
            <ToggleButton onClick={handleToggleExpand}>
              {isExpanded ? "접기" : "더보기"}
            </ToggleButton>
          )}
        </Contents>
      </Summary>
    </Wrapper>
  );
};
/* Summary 내부 비율 = Title : Contents : 나머지 */
/*  width 비율 = Title : Contents : 나머지 = 11% 81% 8% */
/*  height 비율 = 나머지 : Summary(1) : Summary(2) : Summary(3) = 5% 20% 50% 25% */
const Wrapper = styled.div`
  /* 부모 ResultForm 높이 중 68% 차지 */
  /* 부모 ComparisonForm 높이 중 41% 차지 */
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Summary = styled.div`
  width: auto;
  display: flex;
  height: ${(props) => props.height};
  margin-top: ${(props) => props.marginTop || 0};
  margin-left: 32px;
  margin-right: 32px;

  /* 모바일 환경 */
  @media (max-width: 932px) {
    width: auto;
    margin: 0 18px;
  }
`;

const Title = styled.div`
  color: rgba(0, 0, 0, 0.3);
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  min-width: max-content;
  margin-top: 2px;
  margin-right: 20px;

  /* 모바일 환경 */
  @media (max-width: 932px) {
    margin-top: 1.5px;
    margin-right: 15px;
  }
`;

const Contents = styled.div`
  color: #000;
  text-align: justify;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.16px;
  width: 100%;

  /* 모바일 환경 */
  @media (max-width: 932px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  margin-left: 5px;
  padding: 0;

  /* 모바일 환경 */
  @media (max-width: 932px) {
    font-size: 14px;
  }
`;

export default CaseSummary;
