import { useMediaQuery } from "react-responsive";

// 온보딩페이지 미디어쿼리
// max-width, min-width 둘 다포함
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1440, maxWidth: 10000 });
  return isDesktop ? children : null;
};

const LargeTablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 960, maxWidth: 1439 });
  return isTablet ? children : null;
};

const SmallTablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 580, maxWidth: 959 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  // const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 579 });
  const isMobile = useMediaQuery({ maxWidth: 1439 });
  return isMobile ? children : null;
};

export { Desktop, LargeTablet, SmallTablet, Mobile };
// export { Desktop, Mobile };

/*
Large Desktop / 1920-1440
Small Desktop, Large Tablet / 1439-960
Medium, Small Tablet / 959-580
Mobile / 579-320
*/

//  /* Desktop / 1920-1440 */
// @media (max-width: 1920px) and (min-width: 1440px) {
//   }

//   /* Small Desktop, Large Tablet / 1439-960 */
// @media (max-width: 1439px) and (min-width: 960px) {
// }

// /* Medium, Small Tablet / 959-580 */
// @media (max-width: 959px) and (min-width: 580px) {
// }

// /* Mobile / 579-320 */
// @media (max-width: 579px) and (min-width: 320px) {
// }
