import React, { useEffect, useState, useRef } from "react";
import { Desktop, Mobile } from "../MediaQuery/MainPageMediaQuery.js";
import styled from "styled-components";
import Banner from "../Components/Banner.jsx";
import BannerMobile from "../Components/BannerMobile.jsx";
import LogBar from "../Components/LogBar.jsx";
import ModalTermsOfService from "../Components/Modal/ModalTermsOfService.jsx";
import Ask from "../Components/Ask.jsx";
import AskMobile from "../Components/AskMobile.jsx";
import Loading from "../Components/Loading.jsx";
import Text from "../Components/Text.jsx";
import HeightInterval from "../Components/HeightInterval.jsx";
import ModalInquiry from "../Components/Modal/ModalInquiry.jsx";
import ModalWelcomeSignedUp from "../Components/Modal/ModalWelcomeSignedUp.jsx";
import ModalUnauthorizedUser from "../Components/Modal/ModalUnauthorizedUser.jsx";
import useStore from "../store.js";
import config from "../config.js";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ModaRefreshlDetected from "../Components/Modal/ModaRefreshlDetected.jsx";
import Stop from "../Components/Stop";
import { Helmet } from "react-helmet-async";

const Main = () => {
  const [showModal, setShowModal] = useState(false); // 회원가입축하 모달창
  const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false); // Unauthorized 모달창 상태 변수

  const SERVER_URI = config.serverUri;
  const navigate = useNavigate();
  const initialRender = useRef(true);
  const {
    loading,
    userLawHistory,
    updateUserInfo,
    updateUserLaw,
    deleteCookieByKey,
    isRefreshDetected,
    setRefreshDetected,
    initializeStore,
  } = useStore();

  const location = useLocation();
  const textValue = location.state?.textValue || "";

  /* 이용약관 모달창 */
  const [isTermsModalOpen, setTermsModalIsOpen] = useState(false);
  const handleOpenTermsModal = () => {
    setTermsModalIsOpen(true);
  };
  const handleCloseTermsModal = () => {
    setTermsModalIsOpen(false);
  };

  /* 문의하기 모달창 */
  const [isInquiryModalOpen, setInquiryModalIsOpen] = useState(false);
  const handleOpenInquiryModal = () => {
    setInquiryModalIsOpen(true);
  };
  const handleCloseInquiryModal = () => {
    setInquiryModalIsOpen(false);
  };

  const handleConfirmModal = () => {
    setShowModal(false);
  };

  /* 새회원일 경우 회원가입축하 팝업 띄우기 */
  // credit의 내역 개수(length) 및 생성 시간(createdAt) 확인해서 새회원인지 판단
  const checkNewUser = () => {
    // 확인 데이터 개수 설정(기준 3개)으로 연산 줄여서 성능 최적화
    const data = userLawHistory; // 로우 내역

    // 로우 내역 개수가 3개 이상이면 기존 회원이라 return
    if (data.length >= 3) return;
    // 로우 내역 2개 미만이면 새회원인지 확인 시작
    // mainContents에 '회원 축하' 메시지가 포함 되어있는지 확인 후
    // 해당 데이터의 생성시간을 확인
    const welcomeCredit = data.find((item) =>
      item.mainContents.includes("회원 축하")
    );
    if (!welcomeCredit) return;

    // 임시로 50초 설정
    // 해당 로우 내역 생성 시간이 현재 시간(대한민국 기준)과 50초 이하 차이인지 확인
    const createdAt = new Date(welcomeCredit.createdAt);
    const now = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Asia/Seoul" })
    );

    const timeDifference = (now - createdAt) / 1000; // 시간 차이를 초 단위로 계산

    // 50초 이내 회원가입했다면 팝업 띄우기
    if (timeDifference <= 50) {
      setShowModal(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await updateUserInfo(setShowUnauthorizedModal); // 비동기 함수 호출
        await updateUserLaw();
      } catch (error) {
        console.error("Failed to update user info or law history:", error);
      }
    };

    fetchData(); // 비동기 함수 호출
  }, []);

  // 사용자의 userLawHistory 데이터가 있을 경우에만 checkNewUser 실행
  useEffect(() => {
    if (userLawHistory && userLawHistory.length > 0) {
      checkNewUser();
    }
  }, [userLawHistory]);

  // 로딩 화면일 때 스크롤 비활성화
  useEffect(() => {
    if (loading) {
      // loading 상태일 때 스크롤 비활성화
      document.body.style.overflow = "hidden";
    } else {
      // loading 상태가 아닐 때 스크롤 활성화
      document.body.style.overflow = "";
    }

    return () => {
      // 컴포넌트 언마운트 시 스크롤 복구
      document.body.style.overflow = "";
    };
  }, [loading]);

  useEffect(() => {
    // console.log(" Main에서 isRefreshDetected : ", isRefreshDetected);
    if (initialRender.current) {
      initialRender.current = false; // 첫 렌더링 후에 false로 설정
      return;
    }

    if (!showUnauthorizedModal) {
      // Unauthorized 모달이 닫힐 때 로그인 페이지로 리디렉션 수행
      navigate("/");
    }
  }, [showUnauthorizedModal]);

  const closeModal = () => {
    setRefreshDetected(false);
  };

  // 로컬 스토리지와 Zustand store의 상태를 동기화
  useEffect(() => {
    initializeStore();
  }, []);

  return (
    // <Stop />
    <>
      <Helmet>
        <title>나의 상황과 비슷한 판례 찾기 | 로우로우</title>
        <meta
          name="description"
          content="내 상황을 법원은 어떻게 생각할까? 로우로우에서 나의 문제상황을 입력하세요. 비슷한 판례를 찾아드립니다!"
        />
        <meta
          name="keywords"
          content="변호사, 변호사 추천, 선임비용, 무료법률상담, 전화법률상담, 법률상담, 법, 법률, 사기, 이혼, 범죄, 채무"
        />
      </Helmet>
      <ModaRefreshlDetected isOpen={isRefreshDetected} onClose={closeModal} />
      <ModalWelcomeSignedUp isOpen={showModal} onConfirm={handleConfirmModal} />
      {showUnauthorizedModal && (
        <ModalUnauthorizedUser
          isOpen={showUnauthorizedModal}
          onClose={() => setShowUnauthorizedModal(false)}
        />
      )}
      <Desktop>
        {loading && <Loading />}
        <Wrapper isLoading={loading}>
          <LogBar />
          <MainWrapper>
            <Banner />
            <Ask initialTextValue={textValue} />
          </MainWrapper>
        </Wrapper>
      </Desktop>

      <Mobile>
        {loading && <Loading />}
        <WrapperMobile isLoading={loading}>
          <HeightInterval height={24} />
          <BannerMobile />
          <HeightInterval height={36} />
          <AskMobile initialTextValue={textValue} />
          {/* (24-09-04)PC, Mobile 모두 버튼이 사이드바로 이동되기로 디자인 변경되어 주석처리함
          <FooterDiv>
            <HeightInterval height={16} />
            <FooterText>
              <ModalInquiry
                isOpen={isInquiryModalOpen}
                onCloseInquiry={handleCloseInquiryModal}
              />
              <span onClick={() => handleOpenInquiryModal(true)}>문의하기</span>
              <Text> | </Text>
              <ModalTermsOfService
                isOpen={isTermsModalOpen}
                onClose={handleCloseTermsModal}
              />
              <span onClick={() => handleOpenTermsModal(true)}>이용약관</span>
            </FooterText>
            <HeightInterval height={22} />
          </FooterDiv> */}
        </WrapperMobile>
      </Mobile>
    </>
  );
};

const Wrapper = styled.div`
  display: ${({ isLoading }) =>
    isLoading ? "none" : "block"}; // 로딩 상태일 때 숨기기
`;

const WrapperMobile = styled.div`
  display: ${({ isLoading }) =>
    isLoading ? "none" : "block"}; // 로딩 상태일 때 숨기기
  width: auto;
  margin: 0px 24px;

  @media (max-width: 1140px) {
    margin: 0px 36px;
  }
`;

const MainWrapper = styled.div`
  width: calc(100% - 312px); // LogBar의 너비 312px 이외 영역
  margin-left: 312px; /* LogBar의 영역 logBarMinWidth 20% */
`;

// (24-09-04)PC, Mobile 모두 버튼이 사이드바로 이동되기로 디자인 변경되어 주석처리함
// const FooterText = styled.div`
//   color: rgba(0, 0, 0, 0.3);
//   font-family: Pretendard;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 26px;
// `;

// const FooterDiv = styled.div`
//   display: flex;
//   justify-content: center;
//   font-size: 12px;

//   @media (max-width: 599px) {
//     display: none;
//   }
// `;

export default Main;
