import React, { useState, useRef } from "react";
import styled from "styled-components";
import Logo from "./Logo.jsx";
import no_profile from "../img/profile.png";
import ModalMyPage from "../Components/Modal/ModalMyPage.jsx";
import { gradientBorder } from "../styles/mixin.js";

const Banner = () => {
  /* 마이페이지 모달창 */
  const [isMyPageModalOpen, setMyPageModalIsOpen] = useState(false);
  const imgRef = useRef(null);

  const handleOpenMyPageModal = () => {
    setMyPageModalIsOpen(true);
  };
  const handleCloseMyPageModal = () => {
    setMyPageModalIsOpen(false);
  };

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <StyledModalMyPage>
        <ModalMyPage
          isOpen={isMyPageModalOpen}
          onClose={handleCloseMyPageModal}
          width="275px"
          height="auto" /* 마이페이지 높이는 Max 600px */
          title="마이페이지"
          triggerRef={imgRef}
        />
        <StyledImageWrapper
          ref={imgRef}
          onClick={() => imgRef.current && handleOpenMyPageModal()} // 이미지 ref가 유효한 경우에만 모달 열기
        >
          <StyledImage src={no_profile} alt="마이페이지 이미지" />
        </StyledImageWrapper>
      </StyledModalMyPage>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  height: 83px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledModalMyPage = styled.div`
  margin-right: 48px;
  position: absolute; // 부모 컴포넌트 대비 위치 설정
  right: 0; // 부모 컴포넌트의 오른쪽 끝에 배치
`;

const StyledImageWrapper = styled.div`
  ${gradientBorder}

  position: relative;
  width: 40px; // 테두리 두께를 포함한 크기
  height: 40px;
  border-radius: 50%; // 원형 테두리를 유지하기 위해 50%로 설정
  overflow: hidden;
  display: inline-block;

  &:hover {
    cursor: pointer;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  box-sizing: border-box; // 테두리를 포함한 크기 계산
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Banner;
