// console.log("NODE_ENV:", process.env.NODE_ENV);

// const dev = process.env.REACT_APP_SERVER_DOMAIN_DEV;
// const product = process.env.REACT_APP_SERVER_DOMAIN_DEPLOY;

const config = {
  // serverUri: process.env.NODE_ENV === "production" ? product : dev,
  serverUri: process.env.REACT_APP_SERVER_DOMAIN_DEPLOY,
};

// console.log("Config:", config);

export default config;
