import React, { useMemo, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import tooltipContents from "../data/tooltipContents.json";
import tooltipIcon from "../img/tooltip.svg";
import hoverTooltipIcon from "../img/tooltiphover.svg";
import closeTooltipIcon from "../img/close_tooltip_mobile.png";
import { Tooltip } from "antd";
import HeightInterval from "./HeightInterval";
import { Desktop, Mobile } from "../MediaQuery/OnboardingPageMediaQuery.js";

const GlobalStyle = createGlobalStyle`
  .custom-tooltip .ant-tooltip-inner {
    width: 290px;
    height: 368.876px;
    background-color: #fff;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.12);
    border-radius: 24px;
    padding : 0px;

    @media (max-width:599px){
        width: 276px;
        height: 365px;
    }
  }

  .custom-tooltip .ant-tooltip-arrow {
    --antd-arrow-background-color: white !important;
  }
  
  .custom-tooltip .ant-tooltip-arrow-content {
    width: 10px;
    height: 10px;
    position: absolute;
    background: #fff !important;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent !important;
  }
`;

const TooltipComponent = () => {
  const text = (
    <TooltipText>
      {tooltipContents.tooltipContents.map((item, index) => (
        <div key={index}>
          <TooltipTitle>{item.title}</TooltipTitle>
          <HeightInterval height={4} />
          <TooltipContent>{item.contents}</TooltipContent>
          <HeightInterval height={16} />
        </div>
      ))}
    </TooltipText>
  );

  const [hover, setHover] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const mergedArrow = useMemo(() => {
    if (hover || showTooltip) {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [hover, showTooltip]);

  return (
    <TooltipWrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <GlobalStyle />

      <Desktop>
        <Tooltip
          placement="top"
          title={text}
          arrow={mergedArrow}
          overlayClassName="custom-tooltip"
          open={hover}>
          <TooltipIcon
            src={hover ? hoverTooltipIcon : tooltipIcon}
            alt="Tooltip Icon"
          />
        </Tooltip>
      </Desktop>

      {/* open의 hover와 showTooltip를 구분하기 위해 <Tooltip> 을 구분함 */}
      <Mobile>
        <Tooltip
          placement="top"
          title={text}
          arrow={mergedArrow}
          overlayClassName="custom-tooltip"
          open={showTooltip}>
          <TooltipIcon
            src={showTooltip ? closeTooltipIcon : tooltipIcon}
            alt="Tooltip Icon"
            onTouchStart={(e) => {
              e.stopPropagation();
              setShowTooltip(!showTooltip);
            }}
          />
        </Tooltip>
      </Mobile>
    </TooltipWrapper>
  );
};

const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const TooltipIcon = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: block;
`;

const TooltipTitle = styled.div`
  color: #666;
  text-align: justify;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.12px;
`;

const TooltipContent = styled.div`
  color: #666;
  text-align: justify;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.12px;
`;

const TooltipText = styled.div`
  padding: 23px 24px 7px 24px;
`;

export default TooltipComponent;
