import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalBasic from "../Modal/ModalBasic.jsx";

// PWA 웹 앱 설치 묻는 모달창
const ModalAddToHomeScreenPrompt = ({ isOpen, onClose }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowPrompt(true);
      console.log("beforeinstallprompt event triggered");
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      console.log("Event listener removed");
    };
  }, []);

  useEffect(() => {
    if (showPrompt || isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [showPrompt, isOpen]);

  const handleAddToHomeScreen = () => {
    console.log("Add to home screen button clicked");
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
          localStorage.setItem("a2hsPromptDismissed", "true");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
        setShowPrompt(false);
      });
    }
  };

  const handleDismiss = () => {
    setShowPrompt(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <ModalBasic
      isOpen={showPrompt || isOpen}
      onClose={handleDismiss}
      width={window.innerWidth > 430 ? "352px" : "264px"}
      height={window.innerWidth > 430 ? "140px" : "132px"}
      padding="24px">
      <TextWrapper>
        <Header>홈 화면에 바로가기를 설치하시겠습니까?</Header>
        <Content>
          왼쪽 상단의 홈 화면 추가하기 아이콘을 눌러
          <br /> 언제든 설치할 수 있습니다.
        </Content>
      </TextWrapper>
      <ButtonWrapper>
        <ButtonStyles type="cancel" onClick={handleDismiss}>
          닫기
        </ButtonStyles>
        <ButtonStyles
          type="confirm"
          onClick={handleAddToHomeScreen}
          style={{ color: "#00F370" }}>
          설치
        </ButtonStyles>
      </ButtonWrapper>
    </ModalBasic>
  );
};

const TextWrapper = styled.div`
  margin-top: 8px;
  margin-left: 8px;

  @media (max-width: 430px) {
    margin-top: 0px;
    margin-left: 0px;
  }
`;

const Header = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline;

  @media (max-width: 430px) {
    font-size: 16px;
    margin-top: 0px;
    margin-left: 0px;
  }
`;

const Content = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 12px;

  @media (max-width: 430px) {
    margin-top: 10px;
    line-height: 24px;
  }

  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: 430px) {
    margin-top: 16px;
  }
`;

const ButtonStyles = styled.button`
  padding: 8px 16px;
  margin-left: 8px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 6px;
  background: ${(props) => (props.type === "cancel" ? "#F2F2F2" : "#000")};
  color: ${(props) => (props.type === "cancel" ? "#000" : "#FFF")};

  &:disabled {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.3);
    cursor: not-allowed;
  }
`;

export default ModalAddToHomeScreenPrompt;
