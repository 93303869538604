import React, { useEffect } from "react";
import Modal from "react-modal";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    overflow: ${({ isOpen }) => (isOpen ? "hidden !important" : "auto")};
  }
`;

const ModalBasic = ({
  isOpen,
  onClose,
  width,
  height,
  children,
  padding,
  top = "50%",
  left = "50%",
  right,
  className,
}) => {
  useEffect(() => {
    if (isOpen) {
      // iOS 및 Android 환경에서 뷰포트 높이를 동적으로 조정
      const handleResize = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      };
      handleResize(); // 모달이 열렸을 때 초기 설정
      window.addEventListener("resize", handleResize); // 창 크기 변경 시에도 적용
      return () => window.removeEventListener("resize", handleResize); // 모달이 닫힐 때 이벤트 리스너 제거
    }
  }, [isOpen]);

  return (
    <>
      <GlobalStyle isOpen={isOpen} />
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className={className}
        style={{
          ...customModalStyles,
          content: {
            ...customModalStyles.content,
            width: width,
            height: height || "fit-content",
            padding: padding,
            top: top === "50%" ? "calc(var(--vh, 1vh) * 50)" : top, // 모바일 상하단바 존재할 경우 동적 계산하여 정중앙에 위치
            left: left,
            right: right,
            outline: "none",
          },
        }}>
        {children}
      </Modal>
    </>
  );
};

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    width: "100%",
    minHeight: "100vh",
    zIndex: "140",
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
  },
  content: {
    zIndex: "150",
    position: "absolute",
    transform: "translate(-50%, -50%)", // 그대로 유지
    borderRadius: "24px",
    boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
    backgroundColor: "white",
    justifyContent: "center",
    overflow: "auto",
    color: "rgba(0, 0, 0, 0.60)",
    fontFamily: "Pretendard",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
  },
};

export default ModalBasic;
