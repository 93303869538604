import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Info from "../Components/Info";
import SituationBox from "../Components/SituationBox";
import AskForm from "../Components/AskForm";
import HeightInterval from "../Components/HeightInterval.jsx";
import exampleCases from "../data/exampleCases.json";

const useScreenWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
};

const AskMobile = ({ onQueryAction, initialTextValue }) => {
  const screenWidth = useScreenWidth();

  return (
    <Wrapper>
      <Info />
      <HeightInterval height={24} />
      <SituationBox
        title="예시)"
        titleFontSize={14}
        contents={exampleCases.example}
        intervalHeight={12}
      />
      <HeightInterval height={screenWidth >= 600 ? 16 : 12} />
      <AskForm
        onQueryAction={onQueryAction}
        initialTextValue={initialTextValue}
      />
      <Notice>
        *기재한 내용은 AI 학습 데이터로 수집 및 활용되지 않습니다.
      </Notice>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: 100%;

  @media (max-width: 1140px) {
    height: 830px;
  }
`;

const Notice = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
  text-align: center;
`;

export default AskMobile;
