import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ModalInquiry from "./Modal/ModalInquiry.jsx";
import ModalTermsOfService from "../Components/Modal/ModalTermsOfService.jsx";
import LogList from "../Components/LogList.jsx";
import plus from "../img/plus.png";
import inquiry from "../img/inquiry.png";
import terms from "../img/terms.png";
import config from "../config.js";

const LogBar = ({ id }) => {
  const queryId = id;

  /* 이용약관 모달창 */
  const [isTermsModalOpen, setTermsModalIsOpen] = useState(false);
  const handleOpenTermsModal = () => {
    setTermsModalIsOpen(true);
  };
  const handleCloseTermsModal = () => {
    setTermsModalIsOpen(false);
  };

  /* 문의하기 모달창 */
  const [isInquiryModalOpen, setInquiryModalIsOpen] = useState(false);
  const handleOpenInquiryModal = () => {
    setInquiryModalIsOpen(true);
  };
  const handleCloseInquiryModal = () => {
    setInquiryModalIsOpen(false);
  };
  /* 전체 Log 가져오기 */
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    // REACT_APP_SERVER_DOMAIN_DEV
    // REACT_APP_SERVER_DOMAIN_DEPLOY

    // const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN_DEPLOY; // 서버 API 도메인
    const SERVER_URI = config.serverUri;
    const URI = "/private/logs";

    fetch(`${SERVER_URI}${URI}`, {
      credentials: "include", // 인증 정보 포함
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setLogs(data);
      })
      .catch((error) => console.error("Error fetching logs:", error));

    /* localhost 테스트용 질문 내역 */
    // const data = JSON.parse(
    //   '[{"id": 1, "content": "카페에서 음료를 사람에게 쏟았지뭐야? 그 사람에 얼룩진 옷을 세탁하려면 비용이 드는데, 세탁비는 내가 내줘야해?"},{"id": 2, "content": "어제 밤 소나타를 타고 용강네거리에서 동천파출소 쪽으로 신호를 대기하고 있었는데 반대편 차가 내 차의 운전석 모서리 부분을 박았어. 사고 직후, 충격적이어서 정신이 없었지만, 나는 몸을 가누기 위해 애썼고, 머리에서는 여러 생각이 교차했어. 나는 즉시 상황을 파악하기 위해 차에서 내렸고, 상대 차량을 확인했어. 그 차량은 택시였고, 운전자는 술에 취한 듯 보였어. 운전자의 얼굴은 술기운으로 붉게 물들어 있었고, 말투에서도 알코올의 냄새가 진하게 났어. 상황의 심각성을 인지하며, 나는 경찰과 응급 서비스에 연락했어. 결과적으로 나는 몇주간 치료를 받아야돼. 이런 비슷한 케이스가 있어?"}]'
    // );
    // setLogs(data);
  }, [queryId]);

  const navigate = useNavigate();
  const moveToMain = () => {
    navigate("/main");
  };

  return (
    <Wrapper>
      <NewChat>
        <CustomButton
          image_src={plus}
          alt_text="새로운 상황"
          button_text="새로운 상황"
          width="100%"
          height="50px"
          imgWidth={32}
          imgHeight={32}
          gap={8}
          onClick={() => moveToMain()}>
          <ButtonImg
            src={plus}
            alt="새로운 상황"
            imgWidth={32}
            imgHeight={32}
          />
          <ButtonText>새로운 상황</ButtonText>
        </CustomButton>
      </NewChat>

      {/* useEffect 이후 전체 log 기록을 props로 전달 */}
      <LogList logs={logs} />

      <InquiryTermsBox>
        <ModalInquiry
          isOpen={isInquiryModalOpen}
          onCloseInquiry={handleCloseInquiryModal}
        />
        <CustomButton
          image_src={inquiry}
          alt_text="문의하기"
          button_text="문의하기"
          fontSize="16px"
          fontWeight="600"
          width="100%"
          height="44px"
          lineHeight="26px"
          onClick={() => handleOpenInquiryModal(true)}
          imgWidth={32}
          imgHeight={32}
          gap={8}>
          <ButtonImg
            src={inquiry}
            alt="문의하기"
            imgWidth={32}
            imgHeight={32}
          />
          <ButtonText>문의하기</ButtonText>
        </CustomButton>
        <ModalTermsOfService
          isOpen={isTermsModalOpen}
          onClose={handleCloseTermsModal}
        />
        <CustomButton
          image_src={terms}
          alt_text="이용약관"
          button_text="이용약관"
          fontSize="16px"
          fontWeight="600"
          width="100%"
          height="44px"
          lineHeight="26px"
          onClick={() => handleOpenTermsModal(true)}
          imgWidth={32}
          imgHeight={32}
          gap={8}>
          <ButtonImg src={terms} alt="이용약관" imgWidth={32} imgHeight={32} />
          <ButtonText>이용약관</ButtonText>
        </CustomButton>
      </InquiryTermsBox>
    </Wrapper>
  );
};

const logBarMinWidth = "312px"; // 고정

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${logBarMinWidth};
  height: 100%;
  position: fixed;
  background-color: #f9f9f9;
  /* 자식요소들 동일하게 좌우 패딩 설정 */
`;

const NewChat = styled.div`
  height: auto;
  width: auto;
  padding: 16px 32px 53px 32px; /* height가 padding 합해서 119px로 고정 */
  border: none; /* 테두리 스타일 제거 */
`;

const InquiryTermsBox = styled.div`
  // padding: 24px 32px;
  // padding: 24px 32px 29px;
  padding: 24px 32px 0px 32px;
  // margin-bottom: 64px;
  display: flex;
  flex-direction: column;
`;

const CustomButton = styled.button`
  background-color: transparent;
  width: ${(props) => (props.width === "auto" ? "auto" : `${props.width}`)};
  height: ${(props) => (props.height === "auto" ? "auto" : `${props.height}`)};
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: ${(props) => (props.gap !== undefined ? `${props.gap}px` : "0px")};
  cursor: pointer;
  padding: 0px; // 기본 설정된 padding값 삭제

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const ButtonImg = styled.img`
  width: ${(props) =>
    props.imgWidth === "auto" ? "auto" : `${props.imgWidth}px`};
  height: ${(props) =>
    props.imgHeight === "auto" ? "auto" : `${props.imgHeight}px`};
  flex-shrink: 0;
`;

const ButtonText = styled.span`
  font-family: Pretendard;
  font-style: normal;
  color: ${(props) => props.fontColor || "rgba(0, 0, 0, 0.6)"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "600"};
  line-height: ${(props) => props.lineHeight || "26px"};
`;

export default LogBar;
