import React, { useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Onboarding from "./Pages/OnboardingPage.js";
import Main from "./Pages/MainPage.js";
import Query from "./Pages/QueryPage.js";
import Test from "./Pages/TestPage.js";
import TermsOfService from "./Pages/TermsOfService.js";
import PrivacyPolicy from "./Pages/PrivacyPolicy.js";
import KakaoRedirect from "./Components/KakaoRedirect.jsx";
import GoogleRedirect from "./Components/GoogleRedirect.jsx";
import NaverRedirect from "./Components/NaverRedirect.jsx";
import { HelmetProvider } from "react-helmet-async";

// import "./App.css";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const link = document.createElement("link");
    link.href =
      "https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css";
    link.rel = "stylesheet";
    document.head.appendChild(link);
  }, []);

  useEffect(() => {
    // PWA로 설치되어 실행된 경우 앱에 들어오자마자 온보딩 페이지로 리디렉트
    // 임시 - 추후 /main으로 변경 및 로그인 정보 확인 추가
    if (
      window.matchMedia("(display-mode: standalone)").matches &&
      !sessionStorage.getItem("redirected")
    ) {
      sessionStorage.setItem("redirected", "true");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const { pathname } = location; // 현재 경로를 가져옴
    const match = pathname.match(/^\/main\/q\/(\d+)$/);
    // 숫자로 끝나지 않으면 /main으로 리디렉션
    if (pathname.startsWith("/main/q/") && !match) {
      navigate("/main");
    }
  }, [location, navigate]);

  return (
    <div className="App">
      <GlobalStyle />
      <HelmetProvider>
        {/* <BrowserRouter> */}
        <Routes>
          <Route path="/" element={<Onboarding />} />
          <Route path="/main" element={<Main />} />
          <Route path="/main/q/:id" element={<Query />} />
          <Route path="/test" element={<Test />} />
          <Route path="/oauth/kakao" element={<KakaoRedirect />} />
          <Route path="/oauth/google" element={<GoogleRedirect />} />
          <Route path="/oauth/naver" element={<NaverRedirect />} />
          <Route path="/termsofservice" element={<TermsOfService />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/main" />} />
          {/*잘못된 URL 입력시 메인으로 돌아오도록 처리 */}
        </Routes>
      </HelmetProvider>
      {/* </BrowserRouter> */}
    </div>
  );
}

const GlobalStyle = createGlobalStyle`
body {
  font-family: 'Pretendard', sans-serif;
}
* {
  font-family: inherit;
}
`;

export default App;
