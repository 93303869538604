import React, { useState, useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

const RelevanceChart = ({ relevance = 50 }) => {
  const chartRef = useRef(null);

  const [series, setSeries] = useState([relevance]);
  const [options, setOptions] = useState({
    chart: {
      width: 100,
      height: 100,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        inverseOrder: false,
        dataLabels: {
          name: {
            fontSize: "8px", // 텍스트 크기
            color: "#8c8c8c",
            offsetY: -10,
            show: false,
          },
          value: {
            fontSize: "10px", // 숫자의 크기
            color: "#000",
            offsetY: 3,
            show: true,
          },
        },
        hollow: {
          size: "30%", // 중앙의 크기를 조정하여 더 작은 차트에 맞춤
        },
      },
    },
    colors: ["#00e676"], // 초록색
    labels: ["관련도"],
  });

  useEffect(() => {
    setSeries([relevance]);
  }, [relevance]);

  return (
    <ChartContainer>
      <StyledChart
        ref={chartRef}
        options={options}
        series={series}
        type="radialBar"
        height={100} // 차트의 높이를 100px로 설정
        width={100} // 차트의 너비를 100px로 설정
      />
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  width: 50px; /* 차트 컨테이너의 너비를 50px로 설정 */
  height: 45px; /* 관련도 텍스트와 아래 간격을 1px로 하기 위해 height 45px로 설정 1px당 height 5px인듯*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledChart = styled(Chart)`
  /* 숫자 설정 */
  .apexcharts-datalabel-value {
    font-size: 10px; /* 숫자의 크기를 줄임 */
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    font-family: Pretendard;
    font-style: normal;
    line-height: 14px;
  }

  .apexcharts-datalabel-value::after {
    content: "%";
    font-size: 6px; /* % 기호의 폰트 크기를 숫자와 일치시킴 */
    vertical-align: super;
    margin-left: 1px; /* % 기호와 숫자 간의 간격을 조정 */
  }
`;

export default RelevanceChart;
