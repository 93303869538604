import { useMediaQuery } from "react-responsive";

// 로우 충전하기 팝업 미디어쿼리
// max-width, min-width 둘 다포함
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1080, maxWidth: 10000 });
  return isDesktop ? children : null;
};

// const Tablet = ({ children }) => {
//   const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1079 });
//   return isTablet ? children : null;
// };

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1079 });
  return isMobile ? children : null;
};

// export { Desktop, Tablet, Mobile };
export { Desktop, Mobile };

/*
Desktop / 1920-1080
Tablet / 1079-600
Mobile / 599-320
*/

//  /* Desktops / 1920-1080 */
// @media (max-width: 1920px) and (min-width: 1080px) {
//   }

// /* Tablet / 1079-600 */
// @media (max-width: 1079px) and (min-width: 600px) {
// }

// /* Mobile / 599-320 */
// @media (max-width: 599px) and (min-width: 320px) {
// }
