import React from "react";
import styled from "styled-components";
import ModalBasic from "./ModalBasic.jsx";
import welcomeImage from "../../img/pc_signup_celebration_popup.png";

const ModalWelcomeSignedUp = ({ isOpen, onConfirm }) => {
  return (
    <ModalBasic
      isOpen={isOpen}
      width={window.innerWidth > 430 ? "400px" : "312px"}
      height={window.innerWidth > 430 ? "363px" : "296px"}>
      <ContentWrapper>
        <ImageWrapper>
          <WelcomeImage src={welcomeImage} alt="회원가입 축하이미지" />
        </ImageWrapper>
        <TextWrapper>
          <Message>
            회원가입을 진심으로 축하드립니다!
            <br />
            축하 기념 15로우를 지급했습니다.
          </Message>
        </TextWrapper>
        <ButtonStyles onClick={onConfirm}>확인</ButtonStyles>
      </ContentWrapper>
    </ModalBasic>
  );
};
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 195px;
  background: #f0f0f0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media (max-width: 430px) {
    height: 152px;
  }
`;

const WelcomeImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;

  @media (max-width: 430px) {
    padding: 16px;
  }
`;

const Message = styled.div`
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  @media (max-width: 430px) {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
  }
`;

const ButtonStyles = styled.button`
  cursor: pointer;
  margin-left: 8px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 6px;
  background: #000;
  color: #00f370;
  font-style: normal;
  font-size: 16px;
  width: 70px;
  height: 40px;
  margin: 0; /* 버튼의 기본 marigin 0으로 설정*/
  margin-bottom: 24px;

  @media (max-width: 430px) {
    width: 66px;
    height: 36px;
    color: #00f370;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 20px;
  }
`;

export default ModalWelcomeSignedUp;
